import { ChangeEvent } from 'react';
import i18n from "../../i18n";
import './styles.css';

const availableLanguages = [
    { value: 'es', label: 'ES' },
    { value: 'en', label: 'EN' }
]

const LanguageSwitch = () => {
    const currentLanguage = i18n.language;

    const changeLanguage = (event: ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        i18n.changeLanguage(value);
    }

    return (
        <select onChange={changeLanguage} className="language-switch">
            {availableLanguages.map(lang => (
                <option
                    key={lang.value}
                    value={lang.value}
                    selected={currentLanguage === lang.value}
                >
                    {lang.label}
                </option>
            ))}
        </select>
    );
}

export default LanguageSwitch;
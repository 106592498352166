import { useTranslation } from 'react-i18next';
// @ts-ignore
import Stepper from 'react-stepper-horizontal';

import './styles.css';

// https://www.npmjs.com/package/react-stepper-horizontal
const Process = () => {
    const { t } = useTranslation('process');

    return (
        <section id='process'>
            <div className='container process__container'>
                <h2>{t('process:title')}</h2>
                <Stepper
                    activeColor={"#EBBC48"}
                    completeColor={"#EBBC48"}
                    defaultColor={"#EBBC48"}
                    defaultBarColor={"#969799"}
                    completeBarColor={"#969799"}
                    activeTitleColor={"#000"}
                    defaultTitleColor={"#000"}
                    completeTitleColor={"#000"}
                    steps={[
                        { title: t('process:step_one') },
                        { title: t('process:step_two') },
                        { title: t('process:step_three') },
                        { title: t('process:step_four') }
                    ]}
                />
            </div>
        </section>
    )
}

export default Process

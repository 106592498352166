import { useTranslation } from 'react-i18next';
import { TbSquareRoundedNumber1Filled, TbSquareRoundedNumber2Filled, TbSquareRoundedNumber3Filled, TbSquareRoundedNumber4Filled } from "react-icons/tb";
import { ContextConfigParams, Styles, Textures } from 'src/sections/Mouth/types';

import { useState } from 'react';
import { AiFillExperiment } from 'react-icons/ai';
import { IoIosColorPalette } from 'react-icons/io';
import CHROME from '../../../assets/textures/texture_chrome.png';
import GOLD from '../../../assets/textures/texture_gold.png';
import GOLD_PINK from '../../../assets/textures/texture_gold_pink.png';

const TEXTURE_MAP: Record<Textures, string> = {
	[Textures.chrome]: CHROME,
	[Textures.gold]: GOLD,
	[Textures.gold_pink]: GOLD_PINK,
	[Textures.white]: '',
	[Textures.selected]: '',
	[Textures.diamond]: '',
}

interface MouthConfiguratorProps {
	openModal: () => void;
}

const MouthConfigurator = ({
	config,
	setConfig,
	openModal
}: ContextConfigParams & MouthConfiguratorProps) => {
	const { t } = useTranslation('mouth');
	const [isError, setIsError] = useState(false);

	const texturesSelectable = [
		Textures.chrome,
		Textures.gold,
		Textures.gold_pink,
	];

	const stylesSelectable = [
		Styles.smooth,
		Styles.window,
		Styles.beveled
	];

	const noTeethSelected = config.teethSelected.length === 0;
	const noTextureSelected = config.texture === undefined
	const noStyleSelected = config.style === undefined

	return (
		<div className='mouth__configurator'>
			<div className='mouth__configurator__step'>
				<TbSquareRoundedNumber1Filled />
				{t('mouth:steps.one')}
			</div>
			<div className='mouth__configurator__step'>
				<TbSquareRoundedNumber2Filled />
				{t('mouth:steps.two')}
			</div>
			<div className='mouth__configurator__step'>
				<TbSquareRoundedNumber3Filled />
				{t('mouth:steps.three')}
			</div>
			<div className='mouth__configurator__step'>
				<TbSquareRoundedNumber4Filled />
				{t('mouth:steps.four')}
			</div>

			<h3 className='mouth__configurator__elements__title'><IoIosColorPalette />{t('mouth:texture')}</h3>
			<div className='mouth__configurator__elements'>
				{texturesSelectable.map(texture => TEXTURE_MAP[texture] ? (
					<div
						key={`configurator_texture_${texture}`}
						className={`mouth__configurator__element ${texture === config?.texture ? 'selected' : ''}`}
						onClick={() => {
							setIsError(false);
							setConfig({
								...config,
								style: !config?.style ? Styles.smooth : config?.style,
								texture: texture
							})
						}}
					>
						<img className='mouth__configurator__element__img' src={TEXTURE_MAP[texture]} alt={`texture_${texture}`} />
						<p className='mouth__configurator__element__label'>{t(`mouth:textures.${texture}`)}</p>
					</div>
				) : <></>
				)}
			</div>

			<div className="checkbox-wrapper">
				<input
					id="withDiamond"
					className="substituted"
					type="checkbox"
					aria-hidden="true"
					disabled={config.style === Styles.beveled}
				/>
				<label
					htmlFor="withDiamond"
					onClick={() => {
						setConfig({
							...config,
							withDiamond: !config.withDiamond
						})
					}}
				>
					{t('mouth:with_diamond')}
				</label>
			</div>

			<h3 className='mouth__configurator__elements__title'><AiFillExperiment />{t('mouth:style')}</h3>
			<div className='mouth__configurator__elements'>
				{stylesSelectable.map(style => {
					const disabled = style === Styles.beveled && !!config?.withDiamond;

					return (
						<div
							key={`configurator_style_${style}`}
							className={`mouth__configurator__element ${disabled ? 'disabled' : ''} ${style === config?.style ? 'selected' : ''}`}
							onClick={() => {
								setIsError(false);
								setConfig({
									...config,
									style: style
								})
							}}
						>
							<img className='mouth__configurator__element__img' src={TEXTURE_MAP[config.texture ?? Textures.chrome]} alt={`style_${style}`} />
							{style === Styles.window && (
								<div className='mouth__configurator__element__img_window' />
							)}
							<p className='mouth__configurator__element__label'>{t(`mouth:styles.${style}`)}</p>
						</div>
					)
				})}
			</div>
			<button
				className='mouth__configurator__button'
				onClick={() => {
					if (noTeethSelected || noTextureSelected || noStyleSelected) {
						setIsError(true);
					} else {
						openModal();
					}
				}}
			>
				{t('mouth:button')}
			</button>
			{(isError && noTextureSelected) && <p className='mouth__configurator__error'>{t('mouth:errors.texture')}</p>}
			{(isError && noStyleSelected) && <p className='mouth__configurator__error'>{t('mouth:errors.style')}</p>}
			{(isError && noTeethSelected) && <p className='mouth__configurator__error'>{t('mouth:errors.teeth')}</p>}
		</div >
	)
}

export default MouthConfigurator;
import { Dispatch, SetStateAction } from 'react';
import * as THREE from "three";
import { GLTF } from "three-stdlib";

export type GLTFResult = GLTF & {
	nodes: GLTFNodes;
	materials: GLTFMaterials;
};

export interface GLTFNodes {
	jaw_lower: THREE.Mesh;
	jaw_upper: THREE.Mesh;
	tooth_11: THREE.Mesh;
	tooth_11_diamond: THREE.Mesh;
	tooth_11_window: THREE.Mesh;
	tooth_11_window_diamond: THREE.Mesh;
	tooth_21: THREE.Mesh;
	tooth_21_window: THREE.Mesh;
	tooth_21_diamond: THREE.Mesh;
	tooth_21_window_diamond: THREE.Mesh;
	tooth_22: THREE.Mesh;
	tooth_22_window: THREE.Mesh;
	tooth_22_window_diamond: THREE.Mesh;
	tooth_22_diamond: THREE.Mesh;
	tooth_23: THREE.Mesh;
	tooth_23_beveled: THREE.Mesh;
	tooth_23_diamond: THREE.Mesh;
	tooth_23_window: THREE.Mesh;
	tooth_23_window_diamond: THREE.Mesh;
	tooth_24: THREE.Mesh;
	tooth_24_window: THREE.Mesh;
	tooth_24_diamond: THREE.Mesh;
	tooth_24_window_diamond: THREE.Mesh;
	tooth_25: THREE.Mesh;
	tooth_25_window: THREE.Mesh;
	tooth_25_diamond: THREE.Mesh;
	tooth_25_window_diamond: THREE.Mesh;
	tooth_12: THREE.Mesh;
	tooth_12_window: THREE.Mesh;
	tooth_12_diamond: THREE.Mesh;
	tooth_12_window_diamond: THREE.Mesh;
	tooth_13: THREE.Mesh;
	tooth_13_beveled: THREE.Mesh;
	tooth_13_window: THREE.Mesh;
	tooth_13_diamond: THREE.Mesh;
	tooth_13_window_diamond: THREE.Mesh;
	tooth_14: THREE.Mesh;
	tooth_14_window: THREE.Mesh;
	tooth_14_diamond: THREE.Mesh;
	tooth_14_window_diamond: THREE.Mesh;
	tooth_15: THREE.Mesh;
	tooth_15_window: THREE.Mesh;
	tooth_15_diamond: THREE.Mesh;
	tooth_15_window_diamond: THREE.Mesh;
	tooth_41: THREE.Mesh;
	tooth_41_window: THREE.Mesh;
	tooth_41_diamond: THREE.Mesh;
	tooth_41_window_diamond: THREE.Mesh;
	tooth_42: THREE.Mesh;
	tooth_42_window: THREE.Mesh;
	tooth_42_diamond: THREE.Mesh;
	tooth_42_window_diamond: THREE.Mesh;
	tooth_44: THREE.Mesh;
	tooth_44_window: THREE.Mesh;
	tooth_44_window_diamond: THREE.Mesh;
	tooth_44_diamond: THREE.Mesh;
	tooth_45: THREE.Mesh;
	tooth_45_window: THREE.Mesh;
	tooth_45_window_diamond: THREE.Mesh;
	tooth_45_diamond: THREE.Mesh;
	tooth_31: THREE.Mesh;
	tooth_31_window: THREE.Mesh;
	tooth_31_diamond: THREE.Mesh;
	tooth_31_window_diamond: THREE.Mesh;
	tooth_32: THREE.Mesh;
	tooth_32_window: THREE.Mesh;
	tooth_32_diamond: THREE.Mesh;
	tooth_32_window_diamond: THREE.Mesh;
	tooth_33: THREE.Mesh;
	tooth_33_beveled: THREE.Mesh;
	tooth_33_window: THREE.Mesh;
	tooth_33_diamond: THREE.Mesh;
	tooth_33_window_diamond: THREE.Mesh;
	tooth_34: THREE.Mesh;
	tooth_34_window: THREE.Mesh;
	tooth_34_window_diamond: THREE.Mesh;
	tooth_34_diamond: THREE.Mesh;
	tooth_35: THREE.Mesh;
	tooth_35_window: THREE.Mesh;
	tooth_35_window_diamond: THREE.Mesh;
	tooth_35_diamond: THREE.Mesh;
	tooth_43: THREE.Mesh;
	tooth_43_beveled: THREE.Mesh;
	tooth_43_window: THREE.Mesh;
	tooth_43_window_diamond: THREE.Mesh;
	tooth_43_diamond: THREE.Mesh;
	tooth_36: THREE.Mesh;
	tooth_37: THREE.Mesh;
	tooth_46: THREE.Mesh;
	tooth_47: THREE.Mesh;
	tooth_26: THREE.Mesh;
	tooth_27: THREE.Mesh;
	tooth_16: THREE.Mesh;
	tooth_17: THREE.Mesh;
}

interface GLTFMaterials {
	chrome: THREE.MeshStandardMaterial;
	white: THREE.MeshStandardMaterial;
	gold_pink: THREE.MeshStandardMaterial;
	gold: THREE.MeshStandardMaterial;
	gum: THREE.MeshStandardMaterial;
	selected: THREE.MeshStandardMaterial;
	diamond: THREE.MeshStandardMaterial;
}

export interface ContextConfigParams {
	config: Config;
	setConfig: Dispatch<SetStateAction<Config>>;
}

export interface Config {
	teethSelected: number[];
	texture?: Textures;
	style?: Styles;
	withDiamond?: boolean;
}

export enum Textures {
	white = 'white',
	gold = 'gold',
	gold_pink = 'gold_pink',
	chrome = 'chrome',
	selected = 'selected',
	diamond = 'diamond'
}

export enum Styles {
	smooth = 'smooth',
	beveled = 'beveled',
	window = 'window',
}

import { Suspense } from 'react'

import { Helmet } from 'react-helmet'
import Divider from './components/Divider'
import Footer from './components/Footer'
import Header from './components/Header'
import Aboutus from './sections/Aboutus'
import Contact from './sections/Contact'
import Faq from './sections/Faq'
import Features from './sections/Features'
import Mouth from './sections/Mouth'
import Portfolio from './sections/Portfolio'
import Process from './sections/Process'
import Slider from './sections/Slider'
import Video from './sections/Video'

const App = () => {
  return (
    <Suspense fallback={<></>}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dr. Grillz BCN: Eleva tu sonrisa con joyas dentales personalizadas exclusivas en Barcelona</title>
        <meta name="description" content="Descubre la máxima elegancia dental en Dr. Grillz BCN. Explora nuestra exclusiva colección de grillz personalizadas, meticulosamente diseñadas para transformar tu sonrisa en una obra de arte única. Contáctanos para experimentar la fusión de estilo y artesanía en el corazón de Barcelona." />
      </Helmet>
      <Header />
      <Video />
      <Slider />
      <Mouth />
      <Process />
      <Features />
      <Portfolio />
      <Divider />
      <Aboutus />
      <Faq />
      <Contact />
      <Footer />
    </Suspense>
  )
}

export default App
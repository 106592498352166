import IMG from '../../assets/divider.jpeg'

import './styles.css'

// https://css-generators.com/section-divider/
// https://9elements.com/blog/pure-css-diagonal-layouts/

const Divider = () => {
  return (
    <section id='divider' style={{
      backgroundImage: `url(${IMG})`
    }}>
      <div className='top'></div>
      <div className='bottom'></div>
    </section>
  )
}

export default Divider

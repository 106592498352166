import { useTranslation } from 'react-i18next';
import IMG from '../../assets/aboutus.jpeg';

import './styles.css';

const Aboutus = () => {
  const { t } = useTranslation('aboutus');

  return (
    <section id='aboutus'>
      <div className='container aboutus__container'>
        <article className='aboutus__content'>
          <h2>{t('aboutus:title')}</h2>
          <p>{t('aboutus:description_1')}</p>
          <p>{t('aboutus:description_2')}</p>
          <p>{t('aboutus:description_3')}</p>
          <p>{t('aboutus:description_4')}</p>
        </article>

        <div className='aboutus__image'>
          <figure>
            <img src={IMG} alt="aboutus" />
          </figure>
        </div>
      </div>
    </section>
  )
}

export default Aboutus

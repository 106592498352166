import emailjs from 'emailjs-com'
import { ChangeEvent, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { BsCardChecklist, BsChatDots, BsFillCalendarCheckFill, BsLightbulb } from 'react-icons/bs'
import './styles.css'

const Contact = () => {
    const { t } = useTranslation(['common', 'contact']);
    const ref = useRef(null);
    const form = useRef<any>();

    useEffect(() => {
        if (ref.current) {
            const myLatLng = { lat: 41.381750, lng: 2.157000 };

            const map = new (window as any).google.maps.Map(ref.current, {
                center: myLatLng,
                zoom: 13,
                fullscreenControl: false,
                // gestureHandling: "none",
                zoomControl: true,
            });

            new (window as any).google.maps.Marker({
                position: myLatLng,
                map,
                animation: (window as any).google.maps.Animation.DROP,
                title: "DR GRILLZ BCN",
            });
        }
    }, [ref]);

    const sendEmail = (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        emailjs.sendForm('service_dx5ni5b', 'template_b9ztn6c', form.current, 'IL9h0g2iG0xgz2R_e')

        e.target.reset()
    };

    return (
        <section id='contact'>
            <div className='contact__maps' ref={ref} />
            <div className='container contact__container'>
                <p className='contact__title' dangerouslySetInnerHTML={{ __html: `${t('contact:contact_us')} <a href='https://www.instagram.com/dr.grillz.bcn' target='_blank' rel="noreferrer">Instagram</a> ${t('contact:or_by_mail')} <a href='mailto:drgrillzbcn@gmail.com'>drgrillzbcn@gmail.com</a>` }} />
                <div className='contact__subtitle'>
                    <BsChatDots />
                    <p>{t('contact:respond_type')}</p>
                </div>

                <form className='contact__form' ref={form} onSubmit={sendEmail}>
                    <fieldset>
                        <input type="text" name='name' placeholder={t('contact:form.name')} required />
                        <input type="email" name='email' placeholder={t('contact:form.mail')} required />
                        <input type="text" name='phone' placeholder={t('contact:form.phone')} />
                    </fieldset>
                    <fieldset>
                        <textarea name="message" rows={7} placeholder={t('contact:form.explanation')}></textarea>
                    </fieldset>
                    <button className='contact__button' type='submit'>{t('common:submit')}</button>
                </form>

                <div className='contact__info'>
                    <div className='contact__icons'>
                        <BsFillCalendarCheckFill />
                        <p>{t('contact:appointment_only')}</p>
                    </div>
                    <div className='contact__icons'>
                        <BsCardChecklist />
                        <p>{t('contact:after_sales_service')}</p>
                    </div>
                    <div className='contact__icons'>
                        <BsLightbulb />
                        <p>{t('contact:send_us_your_idea')}</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosArrowDropdown, IoIosArrowDropup } from 'react-icons/io';

import './styles.css';

interface FaqOpened {
	[index: number]: boolean;
}

const Faq = () => {
	const { t } = useTranslation('faq');
	const [isOpen, setIsOpen] = useState<FaqOpened>({});

	const handleTitleClick = (index: number) => {
		setIsOpen({
			...isOpen,
			[index]: !isOpen[index]
		})
	}

	const data = [
		{
			title: t('faq:question_1.title'),
			content: t('faq:question_1.content')
		},
		{
			title: t('faq:question_2.title'),
			content: t('faq:question_2.content')
		},
		{
			title: t('faq:question_3.title'),
			content: t('faq:question_3.content')
		},
		{
			title: t('faq:question_4.title'),
			content: t('faq:question_4.content')
		},
		{
			title: t('faq:question_5.title'),
			content: t('faq:question_5.content')
		},
		{
			title: t('faq:question_6.title'),
			content: t('faq:question_6.content')
		},
		{
			title: t('faq:question_7.title'),
			content: t('faq:question_7.content')
		},

	]

	return (
		<section id='faq'>
			<div className='container faq__container'>
				<h2>{t('faq:title')}</h2>
				{data.map((elem, index) => {
					return (
						<div key={`accordion_element_${index}`} className={`accordion-section ${isOpen[index] ? 'open' : ''}`}>
							<div className="accordion-section__title" onClick={() => handleTitleClick(index)}>
								{elem.title}

								<IoIosArrowDropup className={`accordion-section__icon ${isOpen[index] ? 'show' : 'hide'}`} />
								<IoIosArrowDropdown className={`accordion-section__icon ${isOpen[index] ? 'hide' : 'show'}`} />
							</div>

							<div className={`accordion-section__content`}>
								{elem.content}
							</div>
						</div>
					)
				})}
			</div>
		</section>
	)
}

export default Faq

import { useTranslation } from 'react-i18next';
import './styles.css';

const Features = () => {
    const { t } = useTranslation('features');

    return (
        <section id='features'>
            <div className='container features__container'>
                <div className='features__process'>
                    <div className='features__header'>
                        <h3>{t('features:process.title')}</h3>
                    </div>
                    <div className='features__details' dangerouslySetInnerHTML={{ __html: t('features:process.description') }} />
                </div>
                <div className='features__quality'>
                    <div className='features__header'>
                        <h3>{t('features:materials.title')}</h3>
                    </div>
                    <div className='features__details' dangerouslySetInnerHTML={{ __html: t('features:materials.description') }} />
                </div>
                <div className='features__hygiene'>
                    <div className='features__header'>
                        <h3>{t('features:maintenance.title')}</h3>
                    </div>
                    <div className='features__details' dangerouslySetInnerHTML={{ __html: t('features:maintenance.description') }} />
                </div>
                <div className='features__price'>
                    <div className='features__header'>
                        <h3>{t('features:price.title')}</h3>
                    </div>
                    <div className='features__details' dangerouslySetInnerHTML={{ __html: t('features:price.description') }} />
                </div>
            </div>
        </section>
    )
}

export default Features

import { ContextConfigParams } from 'src/sections/Mouth/types';

import './styles.css';

const Mouse2D = ({
	config,
	setConfig
}: ContextConfigParams) => {
	const onClick = (toothId: number) => {
		const newTeethSelected = [...config.teethSelected];

		if (newTeethSelected.includes(toothId)) {
			const indexToRemove = newTeethSelected.findIndex(i => i === toothId)
			newTeethSelected.splice(indexToRemove, 1);
		} else {
			newTeethSelected.push(toothId);
		}

		setConfig({
			...config,
			teethSelected: newTeethSelected
		});
	}

	return (
		<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 220 384" width="220" height="384">
			<g id="Layer 1">
				<g>
					<g>
						<path className="s0" d="m183.5 229.6c-6.9 1.1-10.7 3.6-12.4 16.9-3.2 23.9-4.7 106.8-60.3 106.8-55.6 0-57.7-82.9-60.3-106.8-1.4-13.7-6.5-16.8-12.6-16.9 0 0 21.3 0 38 12 24.8 17.8 40.8 19.8 71.4 0 17-11 36.2-12 36.2-12z" />
						<path className="s1" d="m110.8 334.1c-9.2 0-21-3-30-26.2-4-10.3-6.4-22-8-32.7-0.7-5.4 4.8-9.4 9.7-7.1 8.3 4 17.5 6.8 27.8 6.8 9.2 0 18.4-2.1 28.7-6.8 4.9-2.2 10.2 1.9 9.5 7.2-1.7 11.2-4.2 23.4-8.5 34-8.9 22-20.3 24.8-29.2 24.8z" />
						<path className="s0" d="m200.4 257.5c-3 20.4-7 45.8-24 78.1-19 36.4-50.4 38.1-61.1 38.1-2.8 0-6.2 0-9 0-10.7 0-42.1-1.7-61.1-38.1-17-32.3-22.5-61.2-24.5-81.8-1.7-17.3-0.9-21.8 8.6-23.3 2.9-0.4 5.8-1 8.6-0.9 6.1 0.1 11.2 3.2 12.6 16.9 2.6 23.9 4.7 106.8 60.3 106.8 55.6 0 57.7-82.9 60.3-106.8 1.6-14.9 7.4-17.3 14.2-16.9 2.3 0.1 4.7 0.6 7 0.9 9.5 1.5 10.9 7.8 8.1 27z" />
						<g>
							<path className="s2" d="m29.2 230.4c5.2-0.8 11.4-2.1 15.4 1.1 1.4 1 2.5 2.5 3.3 4.1 0.4 0.7 0.7 1.6 1.1 2.4 0.6 2.2 0.2 0.5 1 3.8 0.5 2.7 0.4 1.8 0.6 3.9 2.4 20 3.8 85.2 40 102.9 12.1 5.9 28.2 5.9 40.4 0 39.7-19.4 37.5-96 40.9-108.1 0.4-1.5 0.2-0.7 0.7-2.5 0.3-0.7 0.1-0.3 0.5-1.2 0.2-0.4 0.4-0.8 0.6-1.2 0.8-1.6 1.9-3.1 3.3-4.1 4.1-3.3 10.2-1.9 15.4-1.1l-0.1 0.3c-4.8-0.5-11-1.6-14.8 1.5-1.2 1-2.2 2.4-2.9 3.9-0.1 0.2-0.1 0.3-0.7 1.7 0 0.1 0 0-0.5 1.8-1.2 5.2-1.1 6.4-1.7 16.7-1.5 23.9-4.8 51.2-16.4 72-7.5 13.6-18.3 23.5-33.5 26.8-10.6 2.3-22.6 1.3-32.6-3.6-6.2-3.1-12-7.8-16.9-14.1-23.3-29.5-21.7-84.4-23.4-94.1-0.2-1.5-0.1-0.9-0.4-2.5-0.2-1.1-0.9-3.5-1.6-4.7-0.7-1.5-1.6-2.9-2.8-3.9-0.7-0.5-1.4-0.9-2.1-1.2-4-1.5-8.1-0.8-12.7-0.3z" />
						</g>
						<g>
							<path className="s3" d="m200.4 257.5c-2.1 14.4-4 26.3-8.2 40.3-3.9 13.2-9.2 25.9-15.6 38.1-3.4 6.3-6.8 11.5-11.7 16.9-12.3 13.4-28.2 20.4-45.7 21.2-4.1 0.2-13.6 0.4-20.6-0.3-17.6-1.8-32.7-9.7-44.4-23.7-2.8-3.4-5-6.9-7.3-10.6-15.2-25.2-23.3-55.9-26.2-85.6 2.1 14 5 26.5 9.4 39.9 3.5 10.7 6.4 17.3 9.2 23.8 5.6 12.9 8.9 20.2 17.5 30.3 12.4 14.6 28.9 22 47.1 22.4 7 0.2 17.4 0.3 24.9-1.1 12.4-2.3 24.1-8.5 33.6-18.6 4.5-4.8 8.3-10.2 11.6-16 10.4-19.2 17.4-37.9 21.9-56.9 1.6-6.7 3.3-14.2 4.5-20.1z" />
						</g>
						<g>
							{/* tooth 47 */}
							<g
								className={`svg_clickable ${config.teethSelected.includes(47) ? 'selected' : ''}`}
								onClick={() => onClick(47)}
							>
								<path className="s4" d="m53.5 277.8c-0.6 1.9-2 3.4-3.6 4.5-2.8 1.8-8.1 3-12.5 3.2-2.6 0.2-4.9 0-6.2-0.6-6.6-3-6.1-20.2-5.9-21.3 0.3-1.2 1.1-2.2 2-2.9 3.2-2.4 9.5-3.4 14.7-3.2 2.3 0.1 4.4 0.4 5.9 0.9 1.6 0.5 3 1.5 3.8 2.9 1.9 3.3 2.7 13.7 1.8 16.5z" />
								<path className="s5" d="m53.5 277.8c-0.6 1.9-2 3.4-3.6 4.5-2.8 1.8-8.1 3-12.5 3.2-1.4 0-2.5-0.3-3.3-0.6-6.5-3-6.1-20.2-5.8-21.3 0.2-1.2 1-2.2 1.9-2.9 2.6-2 7.3-3 11.8-3.2 2.3 0.1 4.4 0.4 5.9 0.9 1.6 0.5 3 1.5 3.8 2.9 1.9 3.3 2.7 13.7 1.8 16.5z" />
								<path className="s6" d="m37.8 283.9c-1.6-0.2-2.3-0.4-2.5-0.5-2.1-1-3.7-4.6-4.5-9.9-0.7-4.8-0.5-9.2-0.4-9.9 0.2-0.6 0.6-1.3 1.2-1.7 2.1-1.6 6.3-2.7 11-2.9 2.1 0.1 4.1 0.4 5.5 0.8 1.2 0.5 2.2 1.2 2.7 2.1 1.7 2.9 2.4 13.1 1.7 15.4-0.4 1.3-1.5 2.5-2.9 3.5-2.4 1.6-7.2 2.8-11.8 3.1z" />
								<path className="s4" d="m38.3 272c-0.5-0.3-1.1-0.8-0.9-1.3 0.2-0.5 1-0.5 1.4-0.3 0.4 0.3 0.7 0.8 1.2 1.1 0.4 0.4 1.1 0.5 1.4 0 0.1-0.2 0.1-0.4 0.1-0.7-0.1-1.2-0.6-2.3-1.5-3-0.4-0.4-0.9-0.6-1.3-1-0.4-0.3-0.8-0.7-0.8-1.3-0.1-0.5 0.2-1.1 0.7-1.2 0.6-0.1 1.1 0.4 1.5 0.8 0.4 0.4 1 0.8 1.6 0.6 0.5-0.2 0.6-0.9 0.7-1.4 0.1-0.6 0.5-1.2 1.1-1.1 0.4 0.1 0.6 0.5 0.5 0.9 0 0.3-0.1 0.7-0.3 1-0.8 1.8-1.2 3.7-1.2 5.7 0 0.3 0.1 0.6 0.3 0.8 0.5 0.3 1-0.1 1.4-0.4 1-0.8 2.4-1.3 3.7-1.4 0.4 0 1 0.1 1.1 0.5 0.2 0.3 0 0.8-0.3 1-0.4 0.2-0.8 0.2-1.1 0.3-1.1 0.1-2.2 0.3-3.2 0.8-1.8 1.1-2.6 3.5-1.8 5.5 0.2 0.5 0.5 1 0.6 1.5 0 0.6-0.3 1.2-0.8 1.3-0.4 0-0.8-0.4-0.9-0.7-0.2-0.4-0.2-0.8-0.2-1.2q0.1-1.5 0.2-2.9c0-0.8 0.1-1.8-0.5-2.4-0.7-0.6-1.9-1.1-2.7-1.5z" />
								<g>
									<path className="s7" d="m36.7 263.1c0.4 0 0.3 0.3 0.1 0.8-1.1 2.1-1.6 4.6-1.3 7q0.1 0.5 0.1 1c0.3 2.5 0.9 3.7 1.6 5.8 0.1 0.5-0.6 0.9-0.9 0.4-2.6-3.4-4.3-11.3-0.7-14.6 0.3-0.3 0.7-0.5 1.1-0.4z" />
								</g>
								<g>
									<path className="s7" d="m47.4 278.7c1.2-1.1 1.5-2.9 2.1-4.4 0.2-0.4 0.7-0.4 0.9 0 0.5 1.1 0.5 2.3 0.1 3.2-0.6 1.4-1.8 2.1-2.9 2-0.4 0-0.5-0.5-0.2-0.8z" />
								</g>
								<g>
									<path className="s7" d="m46.7 262.7c-0.4-0.1-0.5-0.6-0.1-0.8 1.6-1.1 3.5 0.1 3.8 2.6q0.1 0.5-0.1 1.1c0 0.3-0.5 0.4-0.8 0.1-0.8-1-1.4-1.9-1.7-2.3-0.3-0.4-0.6-0.5-1.1-0.7z" />
								</g>
							</g>
							{/* tooth 46 */}
							<g
								className={`svg_clickable ${config.teethSelected.includes(46) ? 'selected' : ''}`}
								onClick={() => onClick(46)}
							>
								<path className="s4" d="m53 310.8c-1.7 0.5-3.4 1-5 1.3-5 1.1-9.4 1.1-11.1-0.5-2.4-2.1-6.1-12.9-5.7-19.2 0.3-4.5 5.7-6.9 8.6-7.9q0 0 0 0c1.2-0.4 1.9-0.6 1.9-0.6 10.6-2.4 12.1 0.1 14 1.5 1.9 1.4 4.2 11.5 4.6 15.9 0.5 4.4-0.5 7.3-7.3 9.5z" />
								<path className="s5" d="m53 310.8c-1.7 0.5-3.4 1-5 1.3-3.9 0.6-7.1 0.4-8.5-0.9-2.4-2.2-6-13.3-5.6-19.7 0.2-3.4 3.2-5.7 5.9-7q0 0 0 0c1.2-0.4 1.9-0.6 1.9-0.6 10.6-2.4 12.1 0.1 14 1.5 1.9 1.4 4.2 11.5 4.6 15.9 0.5 4.4-0.5 7.3-7.3 9.5z" />
								<path className="s6" d="m43 310.1c-1.5-0.1-1.9-0.5-2-0.6-1.7-1.6-5.2-11.9-4.8-17.9 0.1-2.3 2.5-4 4.5-5 0.9-0.3 1.5-0.5 1.5-0.5 3.5-0.8 7.2-1.3 9.2-1.1 1.2 0.1 2 0.4 2.6 0.8 1.1 0.8 1.8 1.8 2.3 3 1.1 3.1 2.4 8.7 2.8 12.4 0.3 3.2-0.9 5.6-6.8 7.4q-2.4 0.8-4.7 1.3c-1.8 0.3-3.4 0.3-4.6 0.2z" />
								<path className="s4" d="m53.1 305.4c0.5 0 1.1-0.1 1.3-0.5 0.2-0.4-0.1-0.9-0.5-1.1-0.4-0.2-0.9-0.2-1.4-0.1-0.4 0.1-0.9 0.2-1.4 0-0.7-0.2-1.1-0.9-1.4-1.6q-0.7-1.4-1.3-2.7c-0.2-0.3-0.3-0.5-0.3-0.8 0-0.5 0.6-0.9 1.1-1 0.5-0.1 1 0 1.5 0.1 1.3 0.3 2.6 0.3 3.8 0 0.4-0.1 0.8-0.2 1-0.4 0.3-0.3 0.4-0.8 0.2-1-0.2-0.3-0.6-0.3-0.9-0.3q-1.9-0.1-3.7-0.2c-0.9 0-1.8-0.1-2.6 0.2-0.8 0.3-1.5 0.9-2.1 1.6-0.1 0.1-0.3 0-0.2-0.1 0.3-2.5 1.2-4.9 2.7-6.9 0.3-0.5 0.7-0.9 0.8-1.5 0.1-0.5-0.3-1.2-0.9-1.2-0.5 0.1-0.8 0.6-0.9 1.1-0.2 0.7-0.2 1.6-1.3 1.8-0.5 0.1-0.9 0-1.3-0.2-1.3-0.4-2.6-0.7-3.9-0.7-0.3 0-0.6 0.1-0.8 0.3-0.3 0.3-0.1 0.9 0.3 1.1 0.3 0.2 0.8 0.2 1.2 0.1 0.5-0.1 0.9-0.1 1.3 0 0.6 0.2 0.9 0.7 1.1 1.2 0.1 0.5 0.1 1.1 0.1 1.7-0.1 1.2-0.1 2.4-0.5 3.5-0.3 1.1-1 2.2-2.1 2.8-0.3 0.1-0.7 0.3-0.8 0.7-0.1 0.5 0.2 1 0.7 1.1 0.8 0.2 1.5-0.3 2.1-0.8 0.7-0.6 1.4-1.3 2.4-1.2 0.8 0.2 1.4 0.7 1.7 1.5 0.4 1.1 0 2.3-0.7 3.2-0.5 0.6-1 1.2 0 1.7 0.8 0.4 1.6-0.5 2.4-0.8 1-0.4 2.1-0.6 3.3-0.6z" />
								<g>
									<path className="s7" d="m52.2 288c-0.1-0.2 0.1-0.6 0.4-0.6 0.5 0.1 1 0.3 1.4 0.7 1.2 1.2 1.7 3.3 1.3 4.9-0.1 0.3-0.5 0.4-0.8 0.2-1-1.1-1.7-2.6-2-4.1 0-0.3-0.1-0.7-0.3-1.1z" />
								</g>
								<g>
									<path className="s7" d="m56.5 299.2c1.8 1.2 1.5 3.8-0.1 4.6-0.2 0.1-0.5 0-0.6-0.3-0.2-0.6-0.4-1.3-0.4-2 0.1-0.6 0.2-1.4 0.5-2.1 0.1-0.3 0.4-0.4 0.6-0.2z" />
								</g>
								<g>
									<path className="s7" d="m40.1 292c0.5 2.1 0.5 5 0 7.1-0.1 0.4-0.6 0.4-0.8 0.1-1.5-2-1.4-5.3 0.1-7.3 0.2-0.3 0.6-0.2 0.7 0.1z" />
								</g>
								<g>
									<path className="s7" d="m41 304.8c0.1-0.3 0.5-0.4 0.7-0.1 0.5 0.6 0.9 1.2 1.6 1.6 0.7 0.5 1.4 0.6 2.2 1 0.3 0.1 0.3 0.5 0.1 0.7-1.2 0.9-2.6 0.9-3.6 0.2-1-0.7-1.5-2.1-1-3.4z" />
								</g>
							</g>
							{/* tooth 45 */}
							<g
								className={`svg_clickable ${config.teethSelected.includes(45) ? 'selected' : ''}`}
								onClick={() => onClick(45)}
							>
								<path className="s4" d="m45.8 313.5c-1.7 0.9-3.4 2-4.3 3.8-1.1 2.1-1.3 6.3 0.5 9.6 1.1 2 2.9 3.6 5.1 4.2 1.9 0.7 4.1 0.5 6.1 0 7.1-1.6 9.4-6 7.9-11.4-1-3.1-2.8-6.9-6.1-8-3.1-1.1-6.3 0.2-9.2 1.8z" />
								<path className="s5" d="m46.9 313.3c-1.7 0.9-3.4 2-4.3 3.8-1.1 2.1-1.3 6.3 0.5 9.6 1.1 2 2.9 3.6 5 4.2 2 0.7 4.1 0.5 6.1 0 7.1-1.6 9.5-6 7.9-11.4-0.9-3.1-2.8-6.9-6.1-8-3.1-1.1-6.2 0.2-9.1 1.8z" />
								<path className="s6" d="m50.7 329.5q-1-0.1-1.8-0.3c-1.7-0.5-3.2-1.8-4.1-3.5-1.7-2.9-1.4-6.6-0.6-8.2 0.8-1.3 2.3-2.3 3.8-3.1 1.8-1 4.2-2.2 6.5-2q0.8 0 1.5 0.2c3 1.1 4.6 5.4 5.1 7.1q1 3.4-0.3 5.6c-1.1 1.8-3.3 3.1-6.5 3.8-1.4 0.3-2.6 0.5-3.6 0.4z" />
								<path className="s4" d="m51.9 328.2c-0.1 0.1-0.3 0.2-0.4 0.2-0.2 0.1-0.4 0-0.5-0.1-0.1-0.2 0-0.5 0.2-0.6 0.2-0.2 0.4-0.2 0.6-0.3 0.8-0.3 1.4-1.1 1.6-2 0.3-0.8 0.2-1.7 0.2-2.6 0-0.4 0-0.8-0.2-1.2-0.1-0.3-0.4-0.6-0.8-0.7-0.4-0.1-0.8 0.1-1.1-0.1-0.3-0.2-0.3-0.7-0.2-1.1 0.1-0.4 0.3-0.8 0.2-1.2-0.2-0.4-0.7-0.7-1.2-0.7-0.4 0-0.9 0.2-1.3 0.4q-0.8 0.3-1.7 0.7c-0.2 0.1-0.5 0.2-0.7 0-0.2-0.1-0.2-0.4-0.2-0.6 0.2-0.7 0.9-1.1 1.6-1.2 0.8-0.2 1.5-0.2 2.2-0.4 1.6-0.4 2.8-2.1 4.4-2.1 0.3 0.4 0 1-0.4 1.4-0.4 0.3-1 0.5-1.3 0.9-0.6 0.7-0.5 1.9 0.3 2.4 0.3 0.2 0.8 0.3 1.1 0.5 0.4 0.4 0.5 1 0.4 1.5 0 0.6-0.2 1.1-0.2 1.6 0 0.6 0.2 1.2 0.6 1.5 0.6 0.3 1.3 0.1 1.9 0 0.4-0.1 1.1 0.1 0.8 0.6-0.1 0.2-0.6 0.3-0.9 0.4q-0.4 0.3-0.9 0.5-1 0.5-1.9 1-1.1 0.6-2.2 1.3z" />
								<g>
									<path className="s7" d="m56.7 316.4c-0.3-0.3 0-0.7 0.3-0.6q0.5 0.2 1 0.8c0.7 0.7 1.4 2.4 1.4 4.1 0 0.3-0.1 0.7-0.2 1.1-0.1 0.3-0.5 0.3-0.6 0q-0.2-0.5-0.3-1-0.3-0.9-0.6-1.8c-0.4-1.1-0.5-2-1-2.6z" />
								</g>
								<g>
									<path className="s7" d="m45.8 321.1c0.6 2.2 1.7 3.8 2.6 5.5 0.2 0.3-0.1 0.6-0.4 0.4-1-0.6-1.8-1.5-2.3-2.5-0.7-1.3-0.8-2.5-0.5-3.4 0.1-0.4 0.6-0.4 0.6 0z" />
								</g>
							</g>
							{/* tooth 44 */}
							<g
								className={`svg_clickable ${config.teethSelected.includes(44) ? 'selected' : ''}`}
								onClick={() => onClick(44)}
							>
								<path className="s4" d="m70 338.3c-0.1 2.4-4.1 5.9-6.7 7.7-0.7 0.5-1.3 0.9-1.7 1.1-1.7 0.8-3.7 0.8-6.1-0.3-2.4-1.1-4.6-5.3-5.3-7.2-0.2-0.7-0.1-1.5 0-2.3 0.4-1.7 1.4-3.2 2.7-4.3q0.5-0.5 1-0.8c3.8-2.6 8.9-3.4 10.4-3.1 1.7 0.4 5.9 6.1 5.7 9.2z" />
								<path className="s5" d="m63.1 346.1c-0.7 0.6-2.2 1.3-2.7 1.5-2.1 0.7-4.2 0.2-6.2-1.3-2.1-1.6-3.6-4.5-4.2-6.6-0.1-0.6-0.1-1.5 0.1-2.3 0.3-1.7 1.3-3.1 2.6-4.3 3.8-2.4 8.7-3.2 10.1-2.9 1.8 0.4 6 6.1 5.8 9.2-0.1 2-3 4.8-5.5 6.7z" />
								<path className="s6" d="m52.8 335.4c1.5-1.9 3.4-3.1 5.6-3.1 3.6 0 8.4 6.8 6.9 9.7-1.3 2.4-2.7 3.6-5.6 4.5-2.7 0.8-4.5-0.8-6.5-3.6-1.4-2-2.2-5.2-0.4-7.5z" />
								<path className="s7" d="m58.5 345.5c0.3-0.2 0.6-0.4 0.6-0.7-0.1-0.2-0.3-0.4-0.4-0.5-2.2-1.6-3.9-3.8-4.8-6.3-0.1-0.2-0.2-0.4-0.3-0.6-0.4-0.5-1-0.3-1.1 0.3 0 0.7 0.2 1.4 0.3 2 0.4 2 3.9 6.4 5.7 5.8z" />
							</g>
							{/* tooth 43 */}
							<g
								className={`svg_clickable ${config.teethSelected.includes(43) ? 'selected' : ''}`}
								onClick={() => onClick(43)}
							>
								<path className="s4" d="m81.2 356.8c-0.9 3.1-2.6 5.6-4.4 6.3-4.6 1.9-11.9-3-14.1-7.6-1.2-2.6-0.6-5.1 1.2-7.4 1.3-1.8 3.3-3.5 5.7-5.2 5.6-3.8 10.2 0.2 10.2 0.2 3.9 3.3 3 8.9 1.4 13.7z" />
								<path className="s5" d="m81.5 354.9c-0.2 1.8-0.7 3.5-1.6 5-0.9 1.6-2 2.7-3.1 3.2-4.6 1.9-11.9-3-14.1-7.6-0.8-1.8-0.8-3.5-0.1-5.2 0.5-1.3 1.5-2.4 2.6-3.3q0.6-0.4 1.2-0.8c2.7-1.9 5.4-3 7.5-3.1 2.9-0.1 6.3 2.4 7.3 5.2 0.7 2.1 0.6 4.3 0.3 6.6z" />
								<path className="s6" d="m74.7 362.6c-3.5 0.2-9.7-4.4-11.2-7.6-0.5-1.1-0.6-2.1-0.1-3.3 0.3-0.8 1-1.6 1.8-2.3 0.4-0.2 0.8-0.6 1.2-0.8 3.9-2.2 12 4.7 12.8 7.4 0.4 1.5-0.4 3.1-1.1 4.3-0.9 1.7-2.1 2-2.3 2.1q-0.5 0.2-1.1 0.2z" />
								<path className="s7" d="m75.1 361.3c0.5-0.3 0.9-0.6 0.7-1-0.1-0.3-0.4-0.4-0.7-0.5-3.7-1.4-7-3.6-9.2-6.5-0.1-0.2-0.3-0.4-0.6-0.6-0.7-0.5-1.6-0.1-1.5 0.7 0.2 0.7 0.6 1.6 1 2.3 1.3 2.3 7.8 6.8 10.3 5.6z" />
							</g>
							{/* tooth 42 */}
							<g
								className={`svg_clickable ${config.teethSelected.includes(42) ? 'selected' : ''}`}
								onClick={() => onClick(42)}
							>
								<path className="s4" d="m93.5 370.4c0 0-2 3.9-8.4 0.6-7.8-3.9-6.1-7.1-6.1-7.1 0.7-1.6 2-4.1 2.1-4.2 1.5-2.6 3.8-6 6.6-8.2 0 0 2-1.6 4.6-0.6 0 0 3.5 0.8 3.6 4 0.1 3.3-0.9 12.1-2.4 15.5z" />
								<path className="s5" d="m95.3 362.7c0 0.2-0.7 5.2-1.8 7.7 0 0-2 3.9-8.4 0.6-7.8-3.9-6.1-7.1-6.1-7.1 0.7-1.6 2-4.1 2.1-4.2 1.6-2.2 3.7-4.6 6-6.4 0 0 2-1.6 4.6-0.5 0 0 3.5 0.8 3.6 3.9 0.1 1.3 0.1 3.6 0 6z" />
								<path className="s6" d="m90 371.8q-1.8 0-4.3-1.2c-5.1-2.7-6-4.9-5.8-5.5 0.1-0.6 0.3-1 0.7-1.7 1.3-1.8 3.2-3 5.3-2.5 2.1 0.6 4.4 1.7 5.9 3 1.4 1.2 2 3.1 1.4 4.9q-0.2 0.8-0.7 1.9c-0.1 0.3-0.9 1.1-2.5 1.1z" />
								<path className="s7" d="m90.9 370.9c0.4-0.2 0.7-0.6 0.5-0.9-0.1-0.2-0.4-0.3-0.6-0.3-3.3-0.8-6.2-2.3-8.3-4.5-0.2-0.1-0.4-0.3-0.6-0.4-0.7-0.4-1.3 0.1-1.2 0.7 0.7 2.4 8.3 6.7 10.2 5.4z" />
							</g>
							{/* tooth 41 */}
							<g
								className={`svg_clickable ${config.teethSelected.includes(41) ? 'selected' : ''}`}
								onClick={() => onClick(41)}
							>
								<path className="s4" d="m108.5 374.7c-2.4 0.4-9.1-0.2-11.1-0.6-2-0.5-1.4-3.8-1.4-3.8 0 0 0.4-2.3 0.9-5.1 0.5-2.8 1.1-5.9 1.6-7.2 1-2.6 1.3-4.5 5.4-4.5 4.2 0 4.8 4.1 5.3 5.5 0.2 0.6 0.6 3.6 0.7 6.8 0.2 4.2 0.1 8.7-1.4 8.9z" />
								<path className="s5" d="m108.5 374.7c-2.4 0.4-9.1-0.2-11.1-0.6-2-0.5-1.4-3.8-1.4-3.8 0 0 0.4-2.3 0.9-5.1 0.5-2.4 1.1-4.4 1.5-5.4 1-2.6 1.5-4.3 5.8-4.3 4.3 0 4.6 4.8 5.1 6.2 0 0.2 0.1 0.7 0.3 1.5q0.4 2.7 0.4 5.4c0 3.2-0.4 6-1.5 6.1z" />
								<path className="s6" d="m98 373.5c-0.5-0.2-1.1-1-1.1-1.4 0-0.4 0.4-3.5 0.8-4.3 0.8-1.2 11.2-1.3 11.3 1.4 0.1 2-0.1 3.3-0.3 4-0.1 0.4-0.7 0.8-1.2 0.9-2 0.5-7.6 0.1-9.5-0.6z" />
								<path className="s7" d="m108.1 372.5c0.2-0.3 0.4-0.5 0.1-0.7-0.2-0.1-0.5 0-0.7 0.1-2.9 0.9-5.8 0.6-8.3-0.4-0.2-0.1-0.5-0.2-0.7-0.3-0.7-0.2-1.2 0.1-0.8 0.6 1.4 1.6 9.1 2.3 10.4 0.7z" />
							</g>
						</g>
						<g>
							{/* tooth 37 */}
							<g
								className={`svg_clickable ${config.teethSelected.includes(37) ? 'selected' : ''}`}
								onClick={() => onClick(37)}
							>
								<path className="s4" d="m168.1 277.8c0.6 1.9 2 3.4 3.6 4.5 2.8 1.8 8.1 3 12.5 3.2 2.6 0.2 4.9 0 6.2-0.6 6.6-3 6.1-20.2 5.9-21.3-0.3-1.2-1.1-2.2-2-2.9-3.2-2.4-9.5-3.4-14.7-3.2-2.3 0.1-4.4 0.4-5.9 0.9-1.6 0.5-3 1.5-3.8 2.9-1.9 3.3-2.7 13.7-1.8 16.5z" />
								<path className="s5" d="m168.1 277.8c0.6 1.9 2 3.4 3.6 4.5 2.8 1.8 8.1 3 12.5 3.2 1.4 0 2.5-0.3 3.3-0.6 6.5-3 6.1-20.2 5.8-21.3-0.2-1.2-1-2.2-1.9-2.9-2.6-2-7.3-3-11.8-3.2-2.3 0.1-4.4 0.4-5.9 0.9-1.6 0.5-3 1.5-3.8 2.9-1.9 3.3-2.7 13.7-1.8 16.5z" />
								<path className="s6" d="m183.8 283.9c1.6-0.2 2.3-0.4 2.6-0.5 2-1 3.6-4.6 4.4-9.9 0.7-4.8 0.5-9.2 0.4-9.9-0.1-0.6-0.6-1.3-1.2-1.7-2.1-1.6-6.3-2.7-11-2.9-2.1 0.1-4.1 0.4-5.5 0.8-1.2 0.5-2.2 1.2-2.7 2.1-1.7 2.9-2.4 13.1-1.7 15.4 0.4 1.3 1.5 2.5 2.9 3.5 2.4 1.6 7.2 2.8 11.8 3.1z" />
								<path className="s4" d="m183.3 272c0.5-0.3 1.1-0.8 0.9-1.3-0.2-0.5-1-0.5-1.4-0.3-0.4 0.3-0.7 0.8-1.2 1.1-0.4 0.4-1.1 0.5-1.4 0-0.1-0.2-0.1-0.4-0.1-0.7 0.1-1.2 0.6-2.3 1.5-3 0.4-0.4 0.9-0.6 1.3-1 0.4-0.3 0.8-0.7 0.8-1.3 0.1-0.5-0.2-1.1-0.7-1.2-0.6-0.1-1.1 0.4-1.5 0.8-0.4 0.4-1 0.8-1.6 0.6-0.5-0.2-0.6-0.9-0.7-1.4-0.1-0.6-0.5-1.2-1.1-1.1-0.4 0.1-0.6 0.5-0.5 0.9 0 0.3 0.1 0.7 0.3 1 0.8 1.8 1.2 3.7 1.2 5.7 0 0.3-0.1 0.6-0.3 0.8-0.5 0.3-1-0.1-1.4-0.4-1-0.8-2.4-1.3-3.7-1.4-0.4 0-1 0.1-1.1 0.5-0.2 0.3 0 0.8 0.3 1 0.4 0.2 0.8 0.2 1.1 0.3 1.1 0.1 2.2 0.3 3.2 0.8 1.8 1.1 2.6 3.5 1.8 5.5-0.2 0.5-0.5 1-0.6 1.5 0 0.6 0.3 1.2 0.8 1.3 0.4 0 0.8-0.4 0.9-0.7 0.2-0.4 0.2-0.8 0.2-1.2q-0.1-1.5-0.2-2.9c0-0.8-0.1-1.8 0.5-2.4 0.7-0.6 1.9-1.1 2.7-1.5z" />
								<g>
									<path className="s7" d="m184.9 263.1c-0.4 0-0.3 0.3-0.1 0.8 1.1 2.1 1.6 4.6 1.3 7q-0.1 0.5-0.1 1c-0.3 2.5-0.9 3.7-1.5 5.8-0.2 0.5 0.5 0.9 0.8 0.4 2.6-3.4 4.3-11.3 0.7-14.6-0.3-0.3-0.7-0.5-1.1-0.4z" />
								</g>
								<g>
									<path className="s7" d="m174.2 278.7c-1.2-1.1-1.5-2.9-2.1-4.4-0.2-0.4-0.7-0.4-0.9 0-0.5 1.1-0.5 2.3-0.1 3.2 0.6 1.4 1.8 2.1 2.9 2 0.4 0 0.5-0.5 0.2-0.8z" />
								</g>
								<g>
									<path className="s7" d="m174.9 262.7c0.4-0.1 0.5-0.6 0.1-0.8-1.6-1.1-3.5 0.1-3.8 2.6q-0.1 0.5 0.1 1.1c0 0.3 0.6 0.4 0.8 0.1 0.8-1 1.4-1.9 1.7-2.3 0.3-0.4 0.6-0.5 1.1-0.7z" />
								</g>
							</g>
							{/* tooth 36 */}
							<g
								className={`svg_clickable ${config.teethSelected.includes(36) ? 'selected' : ''}`}
								onClick={() => onClick(36)}
							>
								<path className="s4" d="m169.1 311.3c1.7 0.6 3.4 1 5.1 1.4 4.9 1 9.4 1 11.1-0.5 2.3-2.2 6-13 5.6-19.3-0.3-4.5-5.6-6.9-8.6-7.9q0 0 0 0c-1.1-0.4-1.9-0.6-1.9-0.6-10.5-2.3-12.1 0.1-14 1.5-1.9 1.4-4.1 11.5-4.6 15.9-0.5 4.4 0.5 7.4 7.3 9.5z" />
								<path className="s5" d="m169.1 311.3c1.7 0.6 3.4 1 5.1 1.4 3.8 0.5 7.1 0.3 8.5-1 2.3-2.2 6-13.3 5.6-19.7-0.3-3.4-3.2-5.7-6-7q0 0 0 0c-1.1-0.4-1.9-0.6-1.9-0.6-10.5-2.3-12.1 0.1-14 1.5-1.9 1.4-4.1 11.5-4.6 15.9-0.5 4.4 0.5 7.4 7.3 9.5z" />
								<path className="s6" d="m179.2 310.6c1.4-0.1 1.9-0.5 1.9-0.6 1.7-1.5 5.3-11.8 4.9-17.8-0.2-2.4-2.5-4.1-4.6-5.1-0.9-0.3-1.5-0.4-1.5-0.4-3.4-0.8-7.2-1.4-9.1-1.2-1.3 0.1-2 0.4-2.6 0.9-1.1 0.7-1.9 1.7-2.3 3-1.1 3-2.4 8.6-2.8 12.3-0.4 3.2 0.9 5.6 6.7 7.5q2.4 0.7 4.8 1.2c1.8 0.3 3.4 0.4 4.6 0.2z" />
								<path className="s4" d="m169.1 305.9c-0.5 0-1.1 0-1.3-0.5-0.3-0.4 0-0.9 0.5-1.1 0.4-0.2 0.9-0.1 1.3-0.1 0.5 0.1 1 0.2 1.4 0.1 0.7-0.3 1.1-1 1.5-1.7q0.6-1.4 1.3-2.7c0.1-0.3 0.2-0.5 0.2-0.8 0-0.5-0.5-0.9-1-1-0.5-0.1-1.1 0.1-1.6 0.2-1.2 0.2-2.5 0.2-3.8-0.1-0.3-0.1-0.7-0.2-1-0.4-0.2-0.3-0.3-0.7-0.1-1 0.2-0.3 0.6-0.3 0.9-0.3q1.8-0.1 3.7-0.2c0.8 0 1.7 0 2.6 0.3 0.8 0.3 1.4 0.9 2.1 1.5 0.1 0.1 0.2 0 0.2-0.1-0.3-2.5-1.3-4.9-2.8-6.9-0.3-0.5-0.7-0.9-0.7-1.5-0.1-0.5 0.3-1.2 0.8-1.1 0.5 0 0.9 0.5 1 1 0.2 0.7 0.2 1.6 1.3 1.8 0.4 0.1 0.9 0 1.3-0.1 1.2-0.5 2.5-0.7 3.8-0.7 0.3 0 0.7 0 0.9 0.2 0.3 0.3 0.1 0.9-0.3 1.1-0.4 0.2-0.9 0.2-1.3 0.1-0.4 0-0.9-0.1-1.3 0-0.5 0.2-0.9 0.7-1 1.2-0.2 0.6-0.2 1.1-0.1 1.7 0 1.2 0.1 2.4 0.4 3.5 0.4 1.1 1.1 2.2 2.1 2.8 0.4 0.1 0.8 0.3 0.9 0.7 0.1 0.5-0.3 1-0.8 1.1-0.8 0.3-1.5-0.2-2.1-0.8-0.6-0.6-1.3-1.3-2.4-1.1-0.8 0.1-1.4 0.7-1.6 1.4-0.5 1.1 0 2.3 0.7 3.2 0.4 0.6 0.9 1.2 0 1.7-0.8 0.4-1.7-0.4-2.4-0.7-1.1-0.5-2.2-0.7-3.3-0.7z" />
								<g>
									<path className="s7" d="m169.9 288.6c0.2-0.3 0-0.7-0.4-0.6-0.4 0-0.9 0.2-1.4 0.6-1.2 1.3-1.7 3.3-1.2 4.9 0.1 0.4 0.5 0.5 0.7 0.2 1.1-1 1.7-2.6 2-4.1 0.1-0.3 0.2-0.6 0.3-1z" />
								</g>
								<g>
									<path className="s7" d="m165.6 299.7c-1.7 1.2-1.4 3.8 0.1 4.6 0.3 0.1 0.5 0 0.6-0.2 0.3-0.7 0.4-1.4 0.4-2.1 0-0.6-0.1-1.4-0.4-2.1-0.1-0.2-0.4-0.3-0.7-0.2z" />
								</g>
								<g>
									<path className="s7" d="m182 292.6c-0.5 2-0.5 4.9 0.1 7 0.1 0.4 0.5 0.5 0.7 0.2 1.5-2.1 1.5-5.4 0-7.4-0.2-0.3-0.7-0.2-0.8 0.2z" />
								</g>
								<g>
									<path className="s7" d="m181.2 305.3c-0.1-0.3-0.5-0.4-0.7-0.1-0.6 0.6-0.9 1.2-1.6 1.7-0.7 0.4-1.5 0.5-2.2 0.9-0.3 0.1-0.4 0.5-0.1 0.7 1.1 0.9 2.5 0.9 3.5 0.2 1.1-0.7 1.6-2 1.1-3.4z" />
								</g>
							</g>
							{/* tooth 35 */}
							<g
								className={`svg_clickable ${config.teethSelected.includes(35) ? 'selected' : ''}`}
								onClick={() => onClick(35)}
							>
								<path className="s4" d="m175.8 313.5c1.7 0.9 3.4 2 4.3 3.8 1.1 2.1 1.3 6.3-0.5 9.6-1.1 2-2.9 3.6-5.1 4.2-1.9 0.7-4.1 0.5-6.1 0-7.1-1.6-9.4-6-7.9-11.4 1-3.1 2.8-6.9 6.1-8 3.1-1.1 6.3 0.2 9.2 1.8z" />
								<path className="s5" d="m174.7 313.3c1.7 0.9 3.4 2 4.3 3.8 1.2 2.1 1.3 6.3-0.5 9.6-1.1 2-2.9 3.6-5 4.2-2 0.7-4.1 0.5-6.1 0-7.1-1.6-9.5-6-7.9-11.4 0.9-3.1 2.8-6.9 6.1-8 3.1-1.1 6.2 0.2 9.1 1.8z" />
								<path className="s6" d="m170.9 329.5q1-0.1 1.8-0.3c1.7-0.5 3.2-1.8 4.1-3.5 1.7-2.9 1.4-6.6 0.6-8.2-0.8-1.3-2.3-2.3-3.8-3.1-1.8-1-4.2-2.2-6.5-2q-0.8 0-1.5 0.2c-3 1.1-4.6 5.4-5.1 7.1q-1 3.4 0.3 5.6c1.1 1.8 3.3 3.1 6.5 3.8 1.4 0.3 2.6 0.5 3.6 0.4z" />
								<path className="s4" d="m169.7 328.2c0.1 0.1 0.3 0.2 0.4 0.2 0.2 0.1 0.4 0 0.5-0.1 0.1-0.2 0-0.5-0.2-0.6-0.2-0.2-0.4-0.2-0.6-0.3-0.8-0.3-1.4-1.1-1.6-2-0.3-0.8-0.2-1.7-0.2-2.6 0-0.4 0-0.8 0.2-1.2 0.1-0.3 0.4-0.6 0.8-0.7 0.4-0.1 0.8 0.1 1.1-0.1 0.3-0.2 0.3-0.7 0.2-1.1-0.1-0.4-0.3-0.8-0.2-1.2 0.2-0.4 0.7-0.7 1.2-0.7 0.4 0 0.9 0.2 1.3 0.4q0.8 0.3 1.7 0.7c0.2 0.1 0.5 0.2 0.7 0 0.2-0.1 0.2-0.4 0.2-0.6-0.2-0.7-0.9-1.1-1.6-1.2-0.8-0.2-1.5-0.2-2.2-0.4-1.6-0.4-2.8-2.1-4.4-2.1-0.3 0.4 0 1 0.4 1.4 0.4 0.3 1 0.5 1.3 0.9 0.6 0.7 0.5 1.9-0.3 2.4-0.3 0.2-0.8 0.3-1.1 0.5-0.4 0.4-0.5 1-0.4 1.5 0 0.6 0.2 1.1 0.2 1.6 0 0.6-0.2 1.2-0.6 1.5-0.6 0.3-1.3 0.1-1.9 0-0.4-0.1-1.1 0.1-0.8 0.6 0.1 0.2 0.6 0.3 0.9 0.4q0.4 0.3 0.9 0.5 1 0.5 1.9 1 1.1 0.6 2.2 1.3z" />
								<g>
									<path className="s7" d="m164.9 316.4c0.3-0.3 0-0.7-0.3-0.6q-0.5 0.2-1 0.8c-0.7 0.7-1.4 2.4-1.4 4.1 0 0.3 0.1 0.7 0.2 1.1 0.1 0.3 0.5 0.3 0.6 0q0.2-0.5 0.3-1 0.3-0.9 0.6-1.8c0.4-1.1 0.5-2 1-2.6z" />
								</g>
								<g>
									<path className="s7" d="m175.8 321.1c-0.6 2.2-1.7 3.8-2.6 5.5-0.2 0.3 0.1 0.6 0.4 0.4 1-0.6 1.8-1.5 2.3-2.5 0.7-1.3 0.8-2.5 0.5-3.4-0.1-0.4-0.6-0.4-0.6 0z" />
								</g>
							</g>
							{/* tooth 34 */}
							<g
								className={`svg_clickable ${config.teethSelected.includes(34) ? 'selected' : ''}`}
								onClick={() => onClick(34)}
							>
								<path className="s4" d="m151.6 338.3c0.1 2.4 4.1 5.9 6.7 7.7 0.7 0.5 1.3 0.9 1.7 1.1 1.7 0.8 3.7 0.8 6.1-0.3 2.4-1.1 4.6-5.3 5.3-7.2 0.2-0.7 0.1-1.5 0-2.3-0.4-1.7-1.4-3.2-2.7-4.3q-0.5-0.5-1-0.8c-3.8-2.6-8.9-3.4-10.4-3.1-1.7 0.4-5.9 6.1-5.7 9.2z" />
								<path className="s5" d="m158.5 346.1c0.7 0.6 2.2 1.3 2.7 1.5 2.1 0.7 4.2 0.2 6.2-1.3 2.1-1.6 3.6-4.5 4.2-6.6 0.1-0.6 0.1-1.5-0.1-2.3-0.3-1.7-1.3-3.1-2.6-4.3-3.8-2.4-8.7-3.2-10.1-2.9-1.8 0.4-6 6.1-5.8 9.2 0.1 2 3 4.8 5.5 6.7z" />
								<path className="s6" d="m168.8 335.4c-1.5-1.9-3.4-3.1-5.6-3.1-3.6 0-8.4 6.8-6.9 9.7 1.3 2.4 2.7 3.6 5.6 4.5 2.7 0.8 4.5-0.8 6.5-3.6 1.4-2 2.2-5.2 0.4-7.5z" />
								<path className="s7" d="m163.1 345.5c-0.3-0.2-0.6-0.4-0.6-0.7 0.1-0.2 0.3-0.4 0.4-0.5 2.2-1.6 3.9-3.8 4.8-6.3 0.1-0.2 0.2-0.4 0.3-0.6 0.4-0.5 1-0.3 1.1 0.3 0 0.7-0.2 1.4-0.3 2-0.4 2-3.9 6.4-5.7 5.8z" />
							</g>
							{/* tooth 33 */}
							<g
								className={`svg_clickable ${config.teethSelected.includes(33) ? 'selected' : ''}`}
								onClick={() => onClick(33)}
							>
								<path className="s4" d="m140.4 356.8c0.9 3.1 2.6 5.6 4.4 6.3 4.6 1.9 11.9-3 14.1-7.6 1.2-2.6 0.6-5.1-1.2-7.4-1.3-1.8-3.3-3.5-5.7-5.2-5.6-3.8-10.2 0.2-10.2 0.2-3.9 3.3-3 8.9-1.4 13.7z" />
								<path className="s5" d="m140.1 354.9c0.2 1.8 0.7 3.5 1.6 5 0.9 1.6 2 2.7 3.1 3.2 4.6 1.9 11.9-3 14.1-7.6 0.8-1.8 0.8-3.5 0.1-5.2-0.5-1.3-1.5-2.4-2.6-3.3q-0.6-0.4-1.2-0.8c-2.7-1.9-5.4-3-7.5-3.1-2.9-0.1-6.3 2.4-7.3 5.2-0.7 2.1-0.6 4.3-0.3 6.6z" />
								<path className="s6" d="m146.9 362.6c3.5 0.2 9.7-4.4 11.2-7.6 0.5-1.1 0.6-2.1 0.1-3.3-0.3-0.8-1-1.6-1.8-2.3-0.4-0.2-0.8-0.6-1.2-0.8-3.9-2.2-12 4.7-12.8 7.4-0.3 1.5 0.4 3.1 1.1 4.3 0.9 1.7 2.1 2 2.3 2.1q0.5 0.2 1.1 0.2z" />
								<path className="s7" d="m146.5 361.3c-0.5-0.3-0.9-0.6-0.7-1 0.1-0.3 0.4-0.4 0.7-0.5 3.7-1.4 7-3.6 9.2-6.5 0.1-0.2 0.3-0.4 0.6-0.6 0.7-0.5 1.6-0.1 1.5 0.7-0.2 0.7-0.6 1.6-1 2.3-1.2 2.3-7.8 6.8-10.3 5.6z" />
							</g>
							{/* tooth 32 */}
							<g
								className={`svg_clickable ${config.teethSelected.includes(32) ? 'selected' : ''}`}
								onClick={() => onClick(32)}
							>
								<path className="s4" d="m128.1 370.4c0 0 2 3.9 8.4 0.6 7.8-3.9 6.1-7.1 6.1-7.1-0.7-1.6-2-4.1-2.1-4.2-1.5-2.6-3.8-6-6.6-8.2 0 0-2-1.6-4.6-0.6 0 0-3.5 0.8-3.6 4-0.1 3.3 0.9 12.1 2.4 15.5z" />
								<path className="s5" d="m126.3 362.7c0 0.2 0.7 5.2 1.8 7.7 0 0 2 3.9 8.4 0.6 7.8-3.9 6.1-7.1 6.1-7.1-0.7-1.6-2-4.1-2.1-4.2-1.6-2.2-3.7-4.6-6-6.4 0 0-2-1.6-4.6-0.5 0 0-3.5 0.8-3.6 3.9-0.1 1.3-0.1 3.6 0 6z" />
								<path className="s6" d="m131.6 371.8q1.8 0 4.3-1.2c5.1-2.7 6-4.9 5.8-5.5-0.1-0.6-0.3-1-0.7-1.7-1.3-1.8-3.2-3-5.3-2.5-2.1 0.6-4.4 1.7-5.9 3-1.4 1.2-2 3.1-1.4 4.9q0.2 0.8 0.7 1.9c0.1 0.3 0.9 1.1 2.5 1.1z" />
								<path className="s7" d="m130.7 370.9c-0.4-0.2-0.7-0.6-0.5-0.9 0.1-0.2 0.4-0.3 0.6-0.3 3.3-0.8 6.2-2.3 8.3-4.5 0.2-0.1 0.4-0.3 0.6-0.4 0.7-0.4 1.3 0.1 1.2 0.7-0.7 2.4-8.3 6.7-10.2 5.4z" />
							</g>
							{/* tooth 31 */}
							<g
								className={`svg_clickable ${config.teethSelected.includes(31) ? 'selected' : ''}`}
								onClick={() => onClick(31)}
							>
								<path className="s4" d="m113.1 374.7c2.4 0.4 9.1-0.2 11.1-0.6 2-0.5 1.4-3.8 1.4-3.8 0 0-0.4-2.3-0.9-5.1-0.5-2.8-1.1-5.9-1.6-7.2-1-2.6-1.3-4.5-5.4-4.5-4.2 0-4.8 4.1-5.3 5.5-0.2 0.6-0.6 3.6-0.7 6.8-0.2 4.2-0.1 8.7 1.4 8.9z" />
								<path className="s5" d="m113.1 374.7c2.4 0.4 9.1-0.2 11.1-0.6 2-0.5 1.4-3.8 1.4-3.8 0 0-0.4-2.3-0.9-5.1-0.5-2.4-1.1-4.4-1.5-5.4-1-2.6-1.5-4.3-5.8-4.3-4.3 0-4.6 4.8-5 6.2-0.1 0.2-0.2 0.7-0.4 1.5q-0.4 2.7-0.4 5.4c0 3.2 0.4 6 1.5 6.1z" />
								<path className="s6" d="m123.6 373.5c0.5-0.2 1.1-1 1.1-1.4 0-0.4-0.4-3.5-0.8-4.3-0.8-1.2-11.2-1.3-11.3 1.4-0.1 2 0.1 3.3 0.3 4 0.1 0.4 0.7 0.8 1.2 0.9 2 0.5 7.6 0.1 9.5-0.6z" />
								<path className="s7" d="m113.5 372.5c-0.2-0.3-0.4-0.5-0.1-0.7 0.2-0.1 0.5 0 0.8 0.1 2.8 0.9 5.7 0.6 8.2-0.4 0.2-0.1 0.5-0.2 0.7-0.3 0.7-0.2 1.2 0.1 0.8 0.6-1.4 1.6-9.1 2.3-10.4 0.7z" />
							</g>
						</g>
					</g>
					<g>
						<path className="s3" d="m180.4 176.9q-0.3-0.1-0.7-0.1-0.2 0-0.5-0.1c-6.8-0.7-22.4-3.6-30.9-15.4-8.2-11.3-23-16.9-37.9-16.8-14.8 0-29.5 5.7-37.6 16.8-8.3 11.5-23.3 14.6-30.3 15.4q-1 0.1-1.8 0.2 0.4-0.1 0.8-0.2h0.1q0.2 0 0.5 0c12-2.2 8.3-28.9 8.4-43 0.1-14.1-3.1-92.1 59.9-92.3h0.2c53.1 0 59.2 55.4 59.9 81.4 0.1 4.8 0.1 8.7 0.1 10.9 0.1 14.1-3.6 40.8 8.4 43q0.2 0 0.4 0 0.5 0.1 1 0.2z" />
						<path className="s1" d="m180 176.9c-5.8-0.5-23-3-32.1-15.6-8.1-11.2-22.8-16.8-37.5-16.8-14.9-0.1-29.8 5.6-38 16.8-8.1 11.3-22.7 14.5-29.8 15.3q-0.6 0.1-1 0.1h-0.1q-0.6 0.1-1.2 0.2 0.7-0.1 1.4-0.2c11-2 8.8-24.5 8.5-39.1q-0.1-2.1-0.1-3.9c0.1-14.1-3.1-92.3 60.1-92.3h0.2c49.5 0.1 58.1 48.5 59.5 75.9 0.4 7.5 0.3 13.4 0.3 16.4q0 1.8 0 3.9c-0.4 14.7-2.6 37.1 8.4 39.1q0.3 0 0.6 0 0.4 0.1 0.8 0.2z" />
						<path className="s0" d="m180 176.9c-5.8-0.5-23-3-32.1-15.6-8.1-11.2-22.8-16.8-37.5-16.8-14.8 0-29.5 5.7-37.6 16.8-8.3 11.5-23.3 14.6-30.3 15.4 0 0-1.8 0.2-1.8 0.2 0-0.1 1.9-0.4 2-0.4 10.2-3.1 8.2-25.2 7.8-39.8 0.5-25.2 6.5-83 60.8-83 42.8 0 55.1 36.1 58.6 63.6q0.4 2.8 0.6 5.5c0.5 5.2 0.7 9.9 0.8 13.9-0.4 14.3-2.2 37.3 8.1 40q0.1 0.1 0.3 0.1 0.2 0 0.3 0.1z" />
						<path className="s1" d="m147.2 137.1c-20.2-16.1-51.1-16.3-71.7-1.2-1.8 1.3-4.3-0.1-4.4-2.5q0 0 0-0.1c0.3-18 2.9-32.8 7.7-43.9 6.6-15.3 17-22.5 32.6-22.5 12.9 0 21.9 4.7 28.5 14.6 9.1 13.9 11.5 34.9 11.9 51.9q0 0.6-0.1 1.3c0 2.4-2.6 3.8-4.5 2.4z" />
						<path className="s0" d="m193.8 169.7c0 0-2.2 7.4-11.4 7.3q-1.1 0-1.9-0.1-0.8-0.1-1.5-0.2c-12-2.2-8.3-28.9-8.4-43-0.1-14.1 3.1-92.3-60-92.3-63.2 0-60 78.2-60.1 92.3 0 5.7 0.6 13.5 0.5 20.9-0.2 10.9-1.8 20.8-8.9 22.1-1 0.1-2 0.3-3.4 0.3h-0.1c-9.1 0-11.3-7.3-11.3-7.3-0.7-1.7-1.3-3.5-1.9-5.3-4.5-13.7-6.9-32.2 4.6-84.1 12.9-58 68.6-60.8 78.9-60.8h3.3c10.3 0 66 2.8 78.9 60.8 13.1 58.9 8.2 74.9 2.7 89.4z" />
						<g>
							<path className="s3" d="m194.2 169.7c-0.6 1.7-2.1 3.8-3.6 5-3.1 2.6-7.6 3-11.5 2.3-9.2-1.5-9.4-16.8-9.3-26.3 0.1-6.3 0.5-12 0.4-17.7 0-9.7-0.1-18.3-1.6-29.4-5.1-36-22.1-61.7-58-61.6-28.9-0.1-51.8 16.7-58.1 61.6-1 7.8-1.5 15.6-1.6 23.5v5.9c-0.1 10.5 1.6 25.8-1.1 35.5-1.3 4.1-3.5 7.8-7.8 8.5q-1.4 0.3-2.9 0.3c-2.9 0.1-6.2-0.8-8.6-2.6-1.4-1.1-2.9-3.2-3.5-5h0.6c0.7 1.7 2 3.2 3.6 4.2 3.1 2.1 6.2 2.4 10.5 1.4 1.7-0.4 2.9-1.3 3.9-2.7 1.8-2.7 2.4-7 2.7-10.3 0.9-13.6-3.4-33.2-0.5-59.4 3-26.2 13.3-51.6 37.6-62 15-6.4 34.9-6.6 50.3 0 24.3 10.4 34.6 35.8 37.6 62 2.9 26.2-1.3 45.8-0.4 59.4 0.2 3.3 0.8 7.6 2.7 10.3 3.5 5.3 12.9 3.6 16.4-0.6 0.6-0.7 1.2-1.5 1.5-2.3z" />
						</g>
						<g>
							<path className="s2" d="m29.8 80.2c1.6-6.4 1.1-4.3 2.8-9.9 0.3-0.7 0.2-0.7 1.8-4.8 0.2-0.5 1.9-4.1 2.2-4.7 1.1-2.1 2.1-4.1 3.3-6.1 4.8-7.8 11.3-14.6 18.7-20 14.9-10.7 34.6-15.8 52-15.6 17.2-0.1 36.9 4.9 51.9 15.6 7.5 5.4 13.9 12.2 18.7 20 1.2 2 2.3 4 3.3 6.1 0.3 0.6 2 4.2 2.2 4.7 1.5 3.9 1.5 4 1.8 4.8 1.7 5.6 1.2 3.6 2.8 9.9l-0.4 0.1c-2.3-7.1-1.5-4.4-2.2-6.5-2-5-1-2.7-2.6-6.3-1.2-2.4-0.6-1.2-1.5-3q-0.8-1.5-1.6-3c-1.1-1.9-2.2-3.9-3.5-5.7-2.6-3.7-5.3-7.2-8.5-10.2-3.2-3.1-6.6-6-10.2-8.4-2-1.3-2.3-1.5-2.8-1.8-1-0.5-1.9-1.1-2.9-1.6-3.3-1.7 0-0.2-5.9-3-11-4.9-25.4-8.1-38.6-7.9-13.5-0.2-27.8 3-38.7 7.9-5.5 2.6-2.8 1.4-5.9 3-1 0.5-1.9 1.1-2.9 1.6-0.5 0.3-0.8 0.5-2.7 1.8-3.7 2.4-7.1 5.3-10.2 8.4-3.2 3.1-6 6.5-8.5 10.2-1.4 1.8-2.5 3.8-3.6 5.7q-0.8 1.5-1.6 3c-0.9 1.8-0.4 0.7-1.4 3-1.7 3.6-0.7 1.3-2.7 6.3-0.7 2.1 0.2-0.6-2.2 6.5z" />
						</g>
						<g>
							{/* tooth 14 */}
							<g
								className={`svg_clickable ${config.teethSelected.includes(14) ? 'selected' : ''}`}
								onClick={() => onClick(14)}
							>
								<path className="s4" d="m61 63.8c-0.4 1.7-3.1 9.2-9 8.9-2.6-0.2-5.4-0.8-7.9-1.6-3.8-1.3-6.9-3.1-7.5-4.5-2.7-6.6 2.8-16.1 6.5-18.3 1.7-1.1 6.2-0.7 12.3 4.2q0.2 0.1 0.4 0.2c5.9 4.7 5.8 8.8 5.2 11.1z" />
								<path className="s5" d="m58.3 64.3c-1.8 5.6-5.3 7.1-7.2 7.4-0.9 0.2-1.6 0.2-1.9 0.2-1.8-0.1-3.5-0.4-5.1-0.8-3.8-1.3-6.9-3.1-7.5-4.5-2.7-6.6 2.8-16.1 6.5-18.3 1.7-1.1 6.2-0.7 12.3 4.2 4.7 4.6 3.4 10.1 2.9 11.8z" />
								<path className="s6" d="m49.3 70.5c-6.8-0.4-11.7-3.4-12.4-4.7-2.4-5.8 2.8-14.8 5.7-16.6 0.1 0 0.9-0.7 1.2-0.7 1.7-0.4 4.6 0 9.5 3.9 5.5 4.5 4.1 10.2 3.7 11.5-2.2 6.9-7.1 6.6-7.7 6.6z" />
								<path className="s5" d="m40.6 63.9c-0.3 0.1-0.5 0.1-0.7 0.3-0.2 0.1-0.4 0.3-0.3 0.6 0.1 0.2 0.4 0.3 0.6 0.3 0.3 0 0.5-0.1 0.7-0.3q1.2-0.6 2.3-1.3c0.5-0.3 1-0.6 1.5-0.4 0.4 0.1 0.8 0.5 1 0.9q0.6 1 1.3 2c0.2 0.4 0.6 0.8 1 0.6 0.2-0.1 0.3-0.5 0.2-0.7-0.1-0.3-0.3-0.5-0.5-0.7-0.8-0.6-1.5-1.3-1.8-2.3-0.4-1.4 0.2-2.9 0.9-4.2 0.4-0.8 0.8-1.6 1.6-2.1 0.9-0.6 2.1-0.5 3.2-0.8 0.4-0.1 0.9-0.4 0.8-0.8-0.1-0.4-0.6-0.4-1-0.3-0.9 0.2-1.8 0.8-2.8 0.6-0.7-0.2-1.2-0.7-1.6-1.4-0.3-0.6-0.6-1.2-1.1-1.8-0.3-0.5-1-0.8-1.5-0.5-0.4 0.3-0.3 1-0.1 1.4 0.4 0.9 1.1 1.7 1.4 2.7 0.3 0.9 0.2 2-0.2 2.9-0.5 1-1.1 2.1-1.6 3.1-0.7 1.1-2.1 1.9-3.3 2.2z" />
								<g>
									<path className="s7" d="m42.1 53c0.8-0.5-0.4 1-1.7 4.4-1.2 3.3-1.4 5.7-1.6 4.4-0.3-2.9 1.2-6.8 3.3-8.8z" />
								</g>
								<g>
									<path className="s7" d="m46.9 51.6c-0.5-0.9 4.5 0.8 3.4 1-1-0.1-2.6-0.6-3.4-1z" />
								</g>
								<g>
									<path className="s7" d="m41.7 66.6c0.9 0.1 2.5 0.6 3.4 1.1 0.1 0.7-4.4-0.4-3.4-1.1z" />
								</g>
								<g>
									<path className="s7" d="m50.1 66.1c0.8-0.8 1.8-1.9 2.3-2.9 0.2-0.4 0.2-0.6 0.4-1.2 0.7-2.3 0.8-2.4 0.9-4.9 0.8 1.8 1.2 3.6 0.5 5.4-0.1 0.4-0.2 0.9-0.5 1.3-0.7 1.3-2.4 1.9-3.6 2.3z" />
								</g>
							</g>
							{/* tooth 13 */}
							<g
								className={`svg_clickable ${config.teethSelected.includes(13) ? 'selected' : ''}`}
								onClick={() => onClick(13)}
							>
								<path className="s5" d="m70.4 45.9c-0.7 4.3-5.9 7.1-7.5 7.5-1.4 0.4-12-4.9-15.1-8.1q-0.1 0 0 0c-0.7-0.7-1.2-1.3-1.2-1.8-0.4-2.7 9.1-13.9 13.3-14.1 0.4 0 1.3 0.7 2.3 1.7q0 0 0 0c2.9 3 8.7 11.6 8.2 14.8z" />
								<path className="s6" d="m51.9 45.4c-1 0.3-3.8-1.5-4.1-2.5-0.3-1 6-10.4 11.3-12.6 0.6-0.2 3 2.7 3.2 3.3 0.4 1.3-3.3 3.8-5.6 6.3-2 2.1-3.8 5.2-4.8 5.5z" />
								<path className="s4" d="m70.4 45.9c-0.7 4.3-5.9 7.1-7.5 7.5-1.4 0.4-12-4.9-15.1-8.1 0.2 0.2 12.5 6.9 14.5 6.2 2-0.8 6.3-4 6.1-6.6-0.2-2.6-4.1-10.9-6.2-13.8 2.9 3 8.7 11.6 8.2 14.8z" />
								<g>
									<path className="s7" d="m50.5 42.2c1.2-3.2 4.7-7.2 8.5-9.4 0.2-0.1 0.4 0.2 0.2 0.3-1.6 1.9-5.4 5.7-8.4 9.3-0.1 0.2-0.4 0-0.3-0.2z" />
								</g>
							</g>
							{/* tooth 12 */}
							<g
								className={`svg_clickable ${config.teethSelected.includes(12) ? 'selected' : ''}`}
								onClick={() => onClick(12)}
							>
								<path className="s5" d="m63.6 26.2c0.5-2.7 12.4-10.5 16.4-9.6 1.8 0.7 7.5 16.9 5.7 20.8-1.8 3.8-7.2 5-8.9 4.9-1.6-0.1-13.6-13.5-13.2-16.1z" />
								<path className="s4" d="m85.7 37.4c-1.8 3.8-7.2 5-8.9 4.9-1.5-0.1-12-11.5-13.1-15.5 2.6 3.8 11.2 12.6 12.6 12.7 1.7 0 6.2-1.1 8-4.9 1.3-2.8-1.6-12.8-3.2-16.6 2.7 4.5 6.1 16.2 4.6 19.4z" />
								<path className="s6" d="m68.1 29.4c-1 0-3.2-2.4-3.2-3.5 0-1 8.6-8.1 14.2-8.6 0.6-0.1 2 3.3 2 3.9 0.1 1.4-4.1 2.7-7 4.3-2.4 1.4-5 3.9-6 3.9z" />
								<g>
									<path className="s7" d="m67 25c2.1-2.7 6.5-5.5 10.7-6.4 0.2 0 0.3 0.2 0.1 0.3-2.1 1.3-6.7 3.8-10.5 6.3-0.2 0.1-0.4-0.1-0.3-0.2z" />
								</g>
							</g>
							{/* tooth 11 */}
							<g
								className={`svg_clickable ${config.teethSelected.includes(11) ? 'selected' : ''}`}
								onClick={() => onClick(11)}
							>
								<g>
									<path className="s5" d="m98.9 37.9c-9.1 0-13.3-16.3-13.6-22q0-0.1 0-0.1c0-0.3 2.5-5.4 12.5-5.4 9.9 0 12.2 2.2 12.4 3.8 0.2 1.6-2.1 23.7-11.3 23.7z" />
									<path className="s6" d="m97.6 19.6c-6.9 0-9.9 2.4-10.3-3.4 0.6-0.8 3-3.7 10.3-3.7 8.9 0 10.2 1.8 10.3 2 1.3 4.4-1.4 4.5-10.3 5.1z" />
									<path className="s4" d="m98.9 37.9c-9.1 0-13.5-16.2-13.7-22 0.1 0.7 3.6 19.6 13.7 19 7.9-0.5 11.1-20.7 11.1-20.7 0.2 1.6-1.9 23.7-11.1 23.7z" />
								</g>
								<g>
									<path className="s7" d="m90.2 14.8c3.8-1.8 10.2-2.1 15.1-0.7 0.1 0.1 0.1 0.4-0.1 0.4-2.9 0.2-9.1 0.1-14.9 0.6-0.2 0-0.2-0.2-0.1-0.3z" />
								</g>
							</g>
							{/* tooth 15 */}
							<g
								className={`svg_clickable ${config.teethSelected.includes(15) ? 'selected' : ''}`}
								onClick={() => onClick(15)}
							>
								<path className="s4" d="m55.1 85.3c-0.4 1.4-2.1 5.4-3.4 6.3-0.9 0.6-1.9 0.9-2.9 1.1-4.1 0.8-8.4 0.2-12.1-1.6-1.7-0.8-3.2-1.9-4.6-3.2-3.6-3.3-4.6-6.9-2.5-11.2 0.6-1.4 1.5-4.4 3.9-5.5 2.5-1.2 5.9-0.7 8.4-0.2q0.6 0.1 1.2 0.2 0 0 0 0c1.8 0.4 3.6 1 5.1 1.9 5.1 3 8.5 6.5 6.9 12.2z" />
								<path className="s5" d="m53.4 84.6c-0.6 1.9-2.4 5.7-3.4 6.3-0.8 0.6-1.9 0.9-2.7 1q-1.8 0.4-3.7 0.4-0.7 0-1.4 0c-1.9-0.2-3.7-0.6-5.5-1.2-1.7-0.8-3.2-1.9-4.6-3.2-3.6-3.3-4.6-6.9-2.5-11.2 0.6-1.4 1.5-4.4 3.9-5.5 2.5-1.2 5.9-0.7 8.4-0.2q0.6 0.1 1.2 0.2 0 0 0 0 1.8 0.6 3.3 1.4 0.2 0.2 0.4 0.3c5.9 3.6 7.9 7.1 6.6 11.7z" />
								<path className="s6" d="m50 84.2c-0.5 1.6-2.4 5-3.1 5.8-0.7 0.8-1.9 1.7-4 1.7q0 0 0 0c-4.2-0.3-7.2-1.7-10.2-4.4-3.4-3.2-4.1-6.4-2.3-10.2q0.2-0.4 0.4-0.9c0.6-1.4 1.4-3.4 3.1-4.2q1.3-0.6 3.4-0.6c1.5 0 3 0.2 4.5 0.5 2.5 0.4 3.4 0.8 4.9 1.7q0.3 0.1 0.5 0.2c5.3 3.3 4 6.1 2.8 10.4z" />
								<path className="s5" d="m43.8 80.9c0.4-0.3-1.3-0.8-1.7-1.2-0.5-0.5-1-1.2-1-2-0.1-0.5 0.1-1.1 0.6-1.2 0.4-0.1 0.7 0 1 0.2 0.3 0.3 0.5 0.6 0.7 0.8 0.6 0.6 1.4 1 2.3 0.9 0.1 0 0.1 0 0.2-0.1 0-0.4-0.3-0.7-0.6-1q-1-0.8-1.9-1.6c-0.8-0.7-1.6-1.4-2.5-1.9-0.8-0.5-1.8-0.8-2.7-0.8-0.2 0-0.3 0.1-0.3 0.2 0 0.4 0.2 0.7 0.5 0.9 0.4 0.3 0.8 0.5 1.1 0.7 0.6 0.5 0.9 1.2 1 1.9 0.3 1.9-0.6 3.9-2.2 4.9-0.4 0.3-0.9 0.5-1.1 1-0.1 0.4 0.1 0.8 0.2 1.3 0.1 0.4 0.2 0.9-0.1 1.2-0.3 0.3-0.7 0.3-1 0.3-0.4-0.1-0.7-0.2-1.1-0.1-0.3 0-0.7 0.3-0.7 0.7 0 0.2 0.3 0.5 0.6 0.5 0.3 0.1 0.6 0 0.9 0 2-0.4 4.1-0.1 5.9 0.8 0.4 0.2 0.8 0.3 1.1 0.1 0.3-0.2 0.3-0.5 0.2-0.8-0.1-0.3-0.4-0.4-0.7-0.5-0.6-0.3-1.2-0.3-1.9-0.3-0.7 0-1.5-0.1-2.1-0.6-0.3-0.3-0.5-0.7-0.5-1 0.1-0.7 0.7-1.1 1.2-1.2 0.5-0.2 1-0.2 1.3-0.5 0.6-0.3 0.9-1 0.9-1.6-0.1-0.3 0.2-0.6 0.6-0.6 0.2 0.1 0.5 0.1 0.5 0.1 0.5 0.1 0.9 0.7 1.3 0.5z" />
								<g>
									<path className="s7" d="m45.4 87.6c-0.2 0.1-0.4-0.2-0.2-0.3 0.6-0.7 1.2-1.5 1.5-2.3 0.2-0.4 0.3-0.6 0.4-1.1 0.7-2 0.8-2.4 0.9-3.9 0-0.3 0.3-0.3 0.4-0.1 0.5 1.5 0.6 2.9 0.1 4.4-0.2 0.4-0.3 0.9-0.5 1.3-0.6 0.9-1.6 1.6-2.6 2z" />
								</g>
								<g>
									<path className="s7" d="m34.9 74.1c-0.3 0.8-0.4 0.9-1.7 4.3-1.3 3.3-1 4.4-1.7 4.4-0.9-1.8 1.9-9.2 3.4-8.7z" />
								</g>
								<g>
									<path className="s7" d="m42.9 73.7c1.2-0.5 4 1.6 4.2 2.9-0.4 0.7-4.2-2.1-4.2-2.9z" />
								</g>
								<g>
									<path className="s7" d="m37.4 88.5c-0.5 0.9 5.3 1.7 5 0.9-0.9-0.5-4.1-0.8-5-0.9z" />
								</g>
							</g>
							{/* tooth 16 */}
							<g
								className={`svg_clickable ${config.teethSelected.includes(16) ? 'selected' : ''}`}
								onClick={() => onClick(16)}
							>
								<path className="s4" d="m52 108.8c-0.7 3.5-1.6 7.2-4.1 9.8-2.2 2.4-5.5 3.6-8.7 3.8-2.5 0.1-5.1-0.3-7.5-1.1q-1-0.3-2.1-0.7c-3.4-1.3-6.7-3.3-8.4-6.6-1.6-3.2-1.2-6.9-0.7-10.3 0.5-3.2 0.1-6.1 2.2-8.9 1.7-2.3 4.4-3.8 7.2-4.1 1.3-0.2 2.6-0.1 3.9 0.1 1.2 0.2 2.4 0.5 3.5 0.8 3.1 0.8 6.3 1.6 9.1 3.2 2.8 1.6 5.2 4.2 5.9 7.3 0.5 2.2 0.1 4.5-0.3 6.7z" />
								<path className="s5" d="m48 107.9c-0.7 3.5-1.6 7.2-4 9.8-2.3 2.4-5.5 3.6-8.8 3.8q-1.8 0.1-3.5-0.2-1-0.3-2.1-0.7c-3.4-1.3-6.7-3.3-8.4-6.6-1.6-3.2-1.2-6.9-0.7-10.3 0.5-3.2 0.1-6.1 2.2-8.9 1.7-2.3 4.4-3.8 7.2-4.1 1.3-0.2 2.6-0.1 3.9 0.1 3 0.8 5.9 1.6 8.6 3.1 2.8 1.6 5.2 4.2 5.9 7.3 0.5 2.2 0.2 4.5-0.3 6.7z" />
								<path className="s6" d="m29.2 118.9c-3.6-1.2-5.5-2.1-7.2-5.4-1.4-2.8-1.1-6.3-0.6-9.6 0.6-3.4 0.2-6 1.9-8.3 1.6-2.1 4.2-3.6 7.2-3.6q1 0 2.3 0.2c3.1 0.8 3.6 0.5 6 1.9 2.8 1.6 4.7 3.8 5.2 6.2 0.5 1.9 0.1 4.1-0.3 6.2-0.6 3.2-1.5 6.7-3.7 9.2-2.2 2.2-5.3 5.2-10.8 3.2z" />
								<path className="s5" d="m26.4 113.7c-0.4 0.1-0.4 0.6-0.1 0.7 1.5 0.6 3.1 0.6 4.6 0 0.8-0.4 1.7-1 2-1.9 0.3-0.9 0-1.8 0.4-2.6 0.4-0.8 1.3-1.1 2.2-1.2 0.8 0 1.6 0.2 2.5 0.4 0.3 0 0.6 0.1 0.9 0 0.3-0.1 0.5-0.4 0.5-0.7-0.2-0.5-0.7-0.5-1.2-0.5q-1.7 0.1-3.5 0.3c-0.4 0-0.9 0-1.3-0.1-0.4-0.1-0.8-0.4-1-0.8-0.1-0.5 0.1-1 0.3-1.5q0.6-1.2 1.2-2.4c0.1-0.3 0.2-0.5 0.4-0.7 0.5-0.3 1.2 0.1 1.6 0.6 0.3 0.5 0.6 1.1 1.1 1.5 0.3 0.2 1 0.2 1-0.3 0.1-0.2-0.1-0.5-0.3-0.6-0.3-0.2-0.6-0.2-0.8-0.3-0.8-0.4-1.2-1.3-1.2-2.2-0.1-0.8 0-1.7-0.2-2.5-0.2-1.2-0.9-2.2-1.7-3.2-0.3-0.4-0.6-0.8-1-1-0.4-0.2-1-0.2-1.3 0.2-0.4 0.4-0.2 1.1 0.2 1.6 0.4 0.4 0.9 0.7 1.3 1.2 0.5 0.6 0.6 1.5 0.4 2.3-0.2 0.8-0.6 1.5-0.9 2.2-0.3 0.5-0.6 0.9-1 1.1-0.7 0.2-1.3-0.3-1.9-0.7-0.7-0.6-1.5-1.2-2.5-1.2-0.4 0.1-0.8 0.3-1.1 0.7-0.5 0.8 0.2 1.6 0.9 1.9 0.6 0.3 1.2 0.3 1.9 0.4 0.9 0.1 1.9 0.6 2 1.5 0.2 1-0.8 1.9-0.7 2.9 0.2 1.4 2.1 2.3 0.4 3.5-1.2 0.8-2.7 1.3-4.1 1.4z" />
								<g>
									<path className="s7" d="m33.8 117.2c-0.4 0-0.6-0.5-0.2-0.7 0.8-0.6 1.6-1.1 2.6-1.9 1-0.8 1.7-1.5 2.3-2.2 0.3-0.3 0.8 0 0.7 0.4-0.6 2.3-3 4.3-5.4 4.4z" />
								</g>
								<g>
									<path className="s7" d="m37.8 96.2c-0.3-0.2 0-0.7 0.4-0.6 1.2 0.4 2.3 1.3 3.1 2.5 0.7 1.2 1 2.6 0.7 3.9-0.1 0.4-0.6 0.4-0.7 0-0.8-2.4-1.7-3.9-3.5-5.8z" />
								</g>
								<g>
									<path className="s7" d="m24.6 99c-0.2 0.2-0.6 0.1-0.7-0.2-0.1-0.8 0.1-1.8 0.7-2.5 0.5-0.7 1.3-1.3 2.2-1.4 0.3 0 0.5 0.4 0.3 0.6-0.2 0.3-0.6 0.8-1.3 1.7-0.7 1-1 1.5-1.2 1.8z" />
								</g>
								<g>
									<path className="s7" d="m23.7 106.5c0.4 1.4 0.7 3.3 0.5 4.8 0 0.3-0.4 0.5-0.7 0.2-1-1.4-1.2-3.5-0.5-5 0.1-0.4 0.6-0.3 0.7 0z" />
								</g>
							</g>
							{/* tooth 17 */}
							<g
								className={`svg_clickable ${config.teethSelected.includes(17) ? 'selected' : ''}`}
								onClick={() => onClick(17)}
							>
								<path className="s4" d="m47.6 134.8c0 1.9-0.1 7.5-3.9 9.7-1.9 1.1-4.7 1.7-7.8 2-3 0.2-6.2 0-9.2-0.3-6-0.7-6.3-6.4-6.4-8.7 0-2.3-0.4-6.5-0.4-6.7-0.9-8.3 4-9.4 6.8-10.2 0.7-0.3 2-0.3 3.5-0.1 4.1 0.3 10.2 1.8 13.5 3.5 4.6 2.2 3.9 8.8 3.9 10.8z" />
								<path className="s5" d="m43.7 134.8c0 2.1-0.1 7.9-3.9 10.2-1.1 0.6-2.4 1.1-3.9 1.5-3 0.2-6.2 0-9.2-0.3-6-0.7-6.3-6.4-6.4-8.7 0-2.3-0.4-6.5-0.4-6.7-0.9-8.3 4-9.4 6.8-10.2 0.7-0.3 2-0.3 3.5-0.1 3.5 0.6 7.2 1.8 9.6 3 4.6 2.3 3.9 9.2 3.9 11.3z" />
								<path className="s6" d="m26.1 144.4c-3.2-0.4-4.7-2.5-4.8-7-0.1-2.3-0.4-6.6-0.4-6.8-0.7-6.3 2.3-7.3 5-8.1 0.7-0.2 2-0.4 3.1-0.2 3.5 0.7 7 1.9 9.1 2.9 3 1.6 2.9 6.5 2.8 8.8q0 0.5 0 0.8c0 4.5-1 7.4-3 8.6q-1.2 0.8-3.2 1.2c-2.8 0.2-5.8 0.1-8.6-0.2z" />
								<path className="s5" d="m30.2 140.7q0.9-0.2 1.8-0.4 0.8-0.2 1.6-0.4 0.9-0.2 1.8-0.4c0.3 0 0.5-0.1 0.6-0.3 0.2-0.2 0-0.6-0.3-0.7-0.4-0.1-0.7 0-1 0.1q-1 0.3-2 0.6c-0.2 0.1-0.4 0.1-0.6 0-0.2-0.1-0.3-0.4-0.4-0.6-0.2-0.8-0.4-1.6-0.3-2.5 0.1-1.5 1.2-2.9 2.7-3.3 0.2 0 0.5-0.1 0.8-0.2 0.2-0.2 0.4-0.4 0.4-0.7-0.1-0.3-0.4-0.5-0.7-0.5-0.3 0-0.6 0.1-0.9 0.3q-0.8 0.4-1.6 0.8c-0.2 0.1-0.6 0.2-0.8 0.1-0.3-0.2-0.3-0.5-0.4-0.8q0-1-0.1-2c0-0.6 0-1.3 0.5-1.7 0.5-0.5 1.4-0.2 2 0.2 0.6 0.4 1.2 0.9 1.9 0.9 0.2-0.1 0.5-0.1 0.6-0.3 0.2-0.2 0.2-0.5 0.1-0.7-0.2-0.2-0.4-0.2-0.7-0.3-1.9-0.3-3.6-1.3-4.9-2.8-0.2-0.3-0.5-0.6-0.8-0.9-0.3-0.3-0.8-0.4-1.2-0.3-0.4 0.1-0.7 0.5-0.6 0.9 0.1 0.5 0.8 0.6 1.2 1 0.7 0.5 0.7 1.4 0.8 2.2q0 1.5 0 2.9c0 0.4 0.1 0.8-0.2 1.1-0.2 0.4-0.5 0.5-0.9 0.6-0.4 0-0.8-0.1-1.1-0.3-0.3-0.2-0.6-0.4-0.9-0.5-0.4-0.1-0.8-0.1-1 0.2-0.2 0.3-0.1 0.7 0.2 1 0.3 0.2 0.7 0.3 1 0.3 0.4 0 0.7 0.1 1.1 0.2 0.6 0.2 1 0.7 1.3 1.3 0.2 0.6 0.3 1.2 0.4 1.8 0.1 1.1 0.2 2.4-0.5 3.2-0.4 0.4-0.9 0.7-1.4 1-0.2 0.2-0.8 0.7-0.7 1 0.1 0.4 0.6 0 0.8-0.1 0.7-0.5 1.5-0.7 2.4-1z" />
								<g>
									<path className="s7" d="m33.8 142.7c-0.2 0-0.3-0.3-0.1-0.4 1-0.5 2.1-0.9 2.9-1.6 0.8-0.8 0.8-1.6 1.1-2.5 0.1-0.2 0.5-0.1 0.5 0.1 0.2 1.2 0.1 2.4-0.7 3.3-0.9 0.8-2.5 1.2-3.7 1.1z" />
								</g>
								<g>
									<path className="s7" d="m36.3 127c-0.1-0.2 0.2-0.5 0.4-0.3 1.1 0.6 1.8 1.8 2.1 3 0.3 1.1 0.3 2.4 0 3.6-0.1 0.3-0.5 0.3-0.5 0-0.6-1.8-0.7-3.8-2-6.3z" />
								</g>
								<g>
									<path className="s7" d="m26 125.1c0.5 0.3-1.5 0.6-1.9 1.3-0.3 0.7-0.4 1.5-0.4 2.3-0.4 3.4-0.2 6.6 0.5 9.8q0.2 1.2 0.5 2.4c0.1 0.8 2.2 1.3 1.7 1.5-0.7 0.1-1.4-0.1-1.9-0.6-0.2-0.2-0.3-0.5-0.4-0.6-2-4-2.5-8.9-1.5-12.7 0.2-0.8 0.4-1.7 1-2.5 0.7-0.9 1.7-1.2 2.4-0.9z" />
								</g>
							</g>
						</g>
						<g>
							{/* tooth 24 */}
							<g
								className={`svg_clickable ${config.teethSelected.includes(24) ? 'selected' : ''}`}
								onClick={() => onClick(24)}
							>
								<path className="s4" d="m160.5 63.8c0.4 1.7 3 9.2 8.9 8.9 2.7-0.2 5.4-0.8 7.9-1.6 3.8-1.3 6.9-3.1 7.5-4.5 2.8-6.6-2.7-16.1-6.4-18.3-1.8-1.1-6.2-0.7-12.4 4.2q-0.2 0.1-0.3 0.2c-5.9 4.7-5.8 8.8-5.2 11.1z" />
								<path className="s5" d="m163.1 64.3c1.8 5.6 5.3 7.1 7.2 7.4 0.9 0.2 1.7 0.2 1.9 0.2 1.9-0.1 3.6-0.4 5.1-0.8 3.8-1.3 6.9-3.1 7.5-4.5 2.8-6.6-2.7-16.1-6.4-18.3-1.8-1.1-6.2-0.7-12.4 4.2-4.6 4.6-3.4 10.1-2.9 11.8z" />
								<path className="s6" d="m172.2 70.5c6.7-0.4 11.7-3.4 12.3-4.7 2.5-5.8-2.7-14.8-5.7-16.6 0 0-0.9-0.7-1.1-0.7-1.8-0.4-4.7 0-9.6 3.9-5.5 4.5-4 10.2-3.6 11.5 2.2 6.9 7 6.6 7.7 6.6z" />
								<path className="s5" d="m180.9 63.9c0.2 0.1 0.4 0.1 0.6 0.3 0.2 0.1 0.4 0.3 0.3 0.6 0 0.2-0.3 0.3-0.6 0.3-0.2 0-0.5-0.1-0.7-0.3q-1.1-0.6-2.3-1.3c-0.4-0.3-0.9-0.6-1.4-0.4-0.5 0.1-0.8 0.5-1.1 0.9q-0.6 1-1.2 2c-0.3 0.4-0.7 0.8-1 0.6-0.3-0.1-0.4-0.5-0.3-0.7 0.1-0.3 0.4-0.5 0.6-0.7 0.7-0.6 1.4-1.3 1.7-2.3 0.5-1.4-0.2-2.9-0.9-4.2-0.4-0.8-0.8-1.6-1.5-2.1-1-0.6-2.2-0.5-3.3-0.8-0.4-0.1-0.9-0.4-0.8-0.8 0.1-0.4 0.7-0.4 1-0.3 1 0.2 1.9 0.8 2.8 0.6 0.7-0.2 1.2-0.7 1.6-1.4 0.4-0.6 0.7-1.2 1.1-1.8 0.4-0.5 1.1-0.8 1.5-0.5 0.4 0.3 0.4 1 0.2 1.4-0.4 0.9-1.2 1.7-1.5 2.7-0.3 0.9-0.1 2 0.3 2.9 0.4 1 1 2.1 1.6 3.1 0.6 1.1 2 1.9 3.3 2.2z" />
								<g>
									<path className="s7" d="m179.4 53c-0.8-0.5 0.3 1 1.6 4.4 1.3 3.3 1.4 5.7 1.7 4.4 0.2-2.9-1.2-6.8-3.3-8.8z" />
								</g>
								<g>
									<path className="s7" d="m174.5 51.6c0.5-0.9-4.4 0.8-3.3 1 0.9-0.1 2.5-0.6 3.3-1z" />
								</g>
								<g>
									<path className="s7" d="m179.8 66.6c-1 0.1-2.6 0.6-3.4 1.1-0.2 0.7 4.3-0.4 3.4-1.1z" />
								</g>
								<g>
									<path className="s7" d="m171.3 66.1c-0.8-0.8-1.8-1.9-2.2-2.9-0.2-0.4-0.3-0.6-0.5-1.2-0.7-2.3-0.7-2.4-0.8-4.9-0.8 1.8-1.2 3.6-0.6 5.4 0.1 0.4 0.3 0.9 0.5 1.3 0.8 1.3 2.5 1.9 3.6 2.3z" />
								</g>
							</g>
							{/* tooth 23 */}
							<g
								className={`svg_clickable ${config.teethSelected.includes(23) ? 'selected' : ''}`}
								onClick={() => onClick(23)}
							>
								<path className="s5" d="m151.1 45.9c0.6 4.3 5.8 7.1 7.5 7.5 1.3 0.4 12-4.9 15.1-8.1q0 0 0 0c0.7-0.7 1.1-1.3 1.2-1.8 0.3-2.7-9.1-13.9-13.3-14.1-0.5 0-1.4 0.7-2.4 1.7q0 0 0 0c-2.9 3-8.6 11.6-8.1 14.8z" />
								<path className="s6" d="m169.5 45.4c1 0.3 3.9-1.5 4.2-2.5 0.3-1-6-10.4-11.4-12.6-0.6-0.2-2.9 2.7-3.1 3.3-0.4 1.3 3.3 3.8 5.6 6.3 1.9 2.1 3.8 5.2 4.7 5.5z" />
								<path className="s4" d="m151.1 45.9c0.6 4.3 5.8 7.1 7.5 7.5 1.3 0.4 12-4.9 15.1-8.1-0.3 0.2-12.6 6.9-14.6 6.2-2-0.8-6.2-4-6-6.6 0.2-2.6 4.1-10.9 6.1-13.8-2.9 3-8.6 11.6-8.1 14.8z" />
								<g>
									<path className="s7" d="m170.9 42.2c-1.2-3.2-4.7-7.2-8.5-9.4-0.1-0.1-0.3 0.2-0.2 0.3 1.7 1.9 5.4 5.7 8.4 9.3 0.2 0.2 0.4 0 0.3-0.2z" />
								</g>
							</g>
							{/* tooth 22 */}
							<g
								className={`svg_clickable ${config.teethSelected.includes(22) ? 'selected' : ''}`}
								onClick={() => onClick(22)}
							>
								<path className="s5" d="m157.8 26.2c-0.4-2.7-12.4-10.5-16.4-9.6-1.7 0.7-7.5 16.9-5.7 20.8 1.9 3.8 7.2 5 8.9 4.9 1.7-0.1 13.7-13.5 13.2-16.1z" />
								<path className="s4" d="m135.7 37.4c1.9 3.8 7.2 5 8.9 4.9 1.5-0.1 12-11.5 13.2-15.5-2.6 3.8-11.3 12.6-12.7 12.7-1.6 0-6.2-1.1-8-4.9-1.3-2.8 1.7-12.8 3.2-16.6-2.7 4.5-6 16.2-4.6 19.4z" />
								<path className="s6" d="m153.3 29.4c1 0 3.2-2.4 3.2-3.5 0-1-8.5-8.1-14.1-8.6-0.7-0.1-2.1 3.3-2.1 3.9 0 1.4 4.2 2.7 7 4.3 2.5 1.4 5 3.9 6 3.9z" />
								<g>
									<path className="s7" d="m154.4 25c-2.1-2.7-6.5-5.5-10.6-6.4-0.2 0-0.3 0.2-0.2 0.3 2.1 1.3 6.7 3.8 10.6 6.3 0.1 0.1 0.3-0.1 0.2-0.2z" />
								</g>
							</g>
							{/* tooth 21 */}
							<g
								className={`svg_clickable ${config.teethSelected.includes(21) ? 'selected' : ''}`}
								onClick={() => onClick(21)}
							>
								<g>
									<path className="s5" d="m122.5 37.9c9.2 0 13.4-16.3 13.6-22q0-0.1 0-0.1c0-0.3-2.4-5.4-12.4-5.4-10 0-12.2 2.2-12.4 3.8-0.2 1.6 2 23.7 11.2 23.7z" />
									<path className="s6" d="m123.9 19.6c6.8 0 9.8 2.4 10.3-3.4-0.6-0.8-3.1-3.7-10.3-3.7-8.9 0-10.3 1.8-10.4 2-1.3 4.4 1.5 4.5 10.4 5.1z" />
									<path className="s4" d="m122.5 37.9c9.1 0 13.5-16.2 13.8-22-0.2 0.7-3.6 19.6-13.7 19-7.9-0.5-11.2-20.7-11.2-20.7-0.2 1.6 1.9 23.7 11.1 23.7z" />
								</g>
								<g>
									<path className="s7" d="m131.2 14.8c-3.8-1.8-10.2-2.1-15-0.7-0.2 0.1-0.2 0.4 0 0.4 2.9 0.2 9.2 0.1 14.9 0.6 0.2 0 0.3-0.2 0.1-0.3z" />
								</g>
							</g>
							{/* tooth 25 */}
							<g
								className={`svg_clickable ${config.teethSelected.includes(25) ? 'selected' : ''}`}
								onClick={() => onClick(25)}
							>
								<path className="s4" d="m166.3 85.3c0.4 1.4 2.2 5.4 3.5 6.3 0.8 0.6 1.8 0.9 2.8 1.1 4.1 0.8 8.4 0.2 12.1-1.6 1.7-0.8 3.3-1.9 4.7-3.2 3.5-3.3 4.5-6.9 2.4-11.2-0.6-1.4-1.5-4.4-3.9-5.5-2.4-1.2-5.8-0.7-8.4-0.2q-0.6 0.1-1.1 0.2-0.1 0-0.1 0c-1.7 0.4-3.5 1-5.1 1.9-5.1 3-8.5 6.5-6.9 12.2z" />
								<path className="s5" d="m168.1 84.6c0.5 1.9 2.3 5.7 3.3 6.3 0.8 0.6 1.9 0.9 2.7 1q1.9 0.4 3.7 0.4 0.7 0 1.4 0c1.9-0.2 3.8-0.6 5.5-1.2 1.7-0.8 3.3-1.9 4.7-3.2 3.5-3.3 4.5-6.9 2.4-11.2-0.6-1.4-1.5-4.4-3.9-5.5-2.4-1.2-5.8-0.7-8.4-0.2q-0.6 0.1-1.1 0.2-0.1 0-0.1 0-1.8 0.6-3.2 1.4-0.3 0.2-0.5 0.3c-5.9 3.6-7.8 7.1-6.5 11.7z" />
								<path className="s6" d="m171.4 84.2c0.5 1.6 2.4 5 3.1 5.8 0.7 0.8 2 1.7 4 1.7q0 0 0 0c4.2-0.3 7.2-1.7 10.2-4.4 3.5-3.2 4.2-6.4 2.3-10.2q-0.2-0.4-0.4-0.9c-0.6-1.4-1.4-3.4-3.1-4.2q-1.3-0.6-3.3-0.6c-1.5 0-3.1 0.2-4.5 0.5-2.6 0.4-3.4 0.8-5 1.7q-0.2 0.1-0.4 0.2c-5.3 3.3-4.1 6.1-2.9 10.4z" />
								<path className="s5" d="m177.7 80.9c-0.5-0.3 1.2-0.8 1.6-1.2 0.6-0.5 1-1.2 1.1-2 0-0.5-0.2-1.1-0.7-1.2-0.3-0.1-0.7 0-1 0.2-0.2 0.3-0.4 0.6-0.7 0.8-0.6 0.6-1.4 1-2.2 0.9-0.1 0-0.2 0-0.2-0.1-0.1-0.4 0.2-0.7 0.6-1q0.9-0.8 1.9-1.6c0.7-0.7 1.5-1.4 2.4-1.9 0.9-0.5 1.8-0.8 2.8-0.8 0.1 0 0.2 0.1 0.2 0.2 0 0.4-0.2 0.7-0.5 0.9-0.3 0.3-0.7 0.5-1.1 0.7-0.6 0.5-0.9 1.2-1 1.9-0.2 1.9 0.7 3.9 2.3 4.9 0.4 0.3 0.9 0.5 1 1 0.2 0.4 0 0.8-0.2 1.3-0.1 0.4-0.2 0.9 0.2 1.2 0.2 0.3 0.6 0.3 1 0.3 0.3-0.1 0.7-0.2 1-0.1 0.4 0 0.8 0.3 0.7 0.7 0 0.2-0.3 0.5-0.6 0.5-0.3 0.1-0.6 0-0.9 0-2-0.4-4.1-0.1-5.9 0.8-0.3 0.2-0.8 0.3-1.1 0.1-0.2-0.2-0.3-0.5-0.1-0.8 0.1-0.3 0.3-0.4 0.6-0.5 0.6-0.3 1.3-0.3 1.9-0.3 0.8 0 1.6-0.1 2.2-0.6 0.2-0.3 0.4-0.7 0.4-1 0-0.7-0.6-1.1-1.2-1.2-0.4-0.2-0.9-0.2-1.3-0.5-0.5-0.3-0.8-1-0.8-1.6 0-0.3-0.3-0.6-0.7-0.6-0.2 0.1-0.4 0.1-0.5 0.1-0.4 0.1-0.9 0.7-1.2 0.5z" />
								<g>
									<path className="s7" d="m176 87.6c0.2 0.1 0.4-0.2 0.3-0.3-0.6-0.7-1.3-1.5-1.6-2.3-0.2-0.4-0.2-0.6-0.4-1.1-0.6-2-0.7-2.4-0.8-3.9 0-0.3-0.3-0.3-0.4-0.1-0.5 1.5-0.6 2.9-0.1 4.4 0.1 0.4 0.2 0.9 0.5 1.3 0.5 0.9 1.6 1.6 2.5 2z" />
								</g>
								<g>
									<path className="s7" d="m186.6 74.1c0.3 0.8 0.3 0.9 1.7 4.3 1.2 3.3 1 4.4 1.7 4.4 0.8-1.8-2-9.2-3.4-8.7z" />
								</g>
								<g>
									<path className="s7" d="m178.5 73.7c-1.1-0.5-4 1.6-4.2 2.9 0.5 0.7 4.2-2.1 4.2-2.9z" />
								</g>
								<g>
									<path className="s7" d="m184.1 88.5c0.4 0.9-5.4 1.7-5 0.9 0.8-0.5 4-0.8 5-0.9z" />
								</g>
							</g>
							{/* tooth 26 */}
							<g
								className={`svg_clickable ${config.teethSelected.includes(26) ? 'selected' : ''}`}
								onClick={() => onClick(26)}
							>
								<path className="s4" d="m169.4 108.8c0.7 3.5 1.7 7.2 4.1 9.8 2.2 2.4 5.5 3.6 8.8 3.8 2.5 0.1 5-0.3 7.4-1.1q1.1-0.3 2.1-0.7c3.4-1.3 6.8-3.3 8.4-6.6 1.6-3.2 1.3-6.9 0.8-10.3-0.5-3.2-0.2-6.1-2.2-8.9-1.8-2.3-4.5-3.8-7.3-4.1-1.3-0.2-2.6-0.1-3.8 0.1-1.2 0.2-2.4 0.5-3.6 0.8-3.1 0.8-6.3 1.6-9 3.2-2.8 1.6-5.2 4.2-6 7.3-0.5 2.2-0.1 4.5 0.3 6.7z" />
								<path className="s5" d="m173.4 107.9c0.7 3.5 1.7 7.2 4.1 9.8 2.2 2.4 5.5 3.6 8.7 3.8q1.8 0.1 3.5-0.2 1.1-0.3 2.1-0.7c3.4-1.3 6.8-3.3 8.4-6.6 1.6-3.2 1.3-6.9 0.8-10.3-0.5-3.2-0.2-6.1-2.2-8.9-1.8-2.3-4.5-3.8-7.3-4.1-1.3-0.2-2.6-0.1-3.8 0.1-3 0.8-6 1.6-8.6 3.1-2.8 1.6-5.2 4.2-6 7.3-0.5 2.2-0.1 4.5 0.3 6.7z" />
								<path className="s6" d="m192.2 118.9c3.6-1.2 5.5-2.1 7.2-5.4 1.4-2.8 1.1-6.3 0.6-9.6-0.5-3.4-0.1-6-1.9-8.3-1.5-2.1-4.2-3.6-7.2-3.6q-1 0-2.2 0.2c-3.2 0.8-3.6 0.5-6.1 1.9-2.7 1.6-4.6 3.8-5.2 6.2-0.4 1.9-0.1 4.1 0.3 6.2 0.7 3.2 1.5 6.7 3.8 9.2 2.1 2.2 5.2 5.2 10.7 3.2z" />
								<path className="s5" d="m195 113.7c0.4 0.1 0.5 0.6 0.1 0.7-1.4 0.6-3.1 0.6-4.5 0-0.9-0.4-1.7-1-2-1.9-0.3-0.9-0.1-1.8-0.5-2.6-0.4-0.8-1.3-1.1-2.1-1.2-0.9 0-1.7 0.2-2.5 0.4-0.3 0-0.7 0.1-0.9 0-0.3-0.1-0.6-0.4-0.5-0.7 0.1-0.5 0.7-0.5 1.1-0.5q1.8 0.1 3.5 0.3c0.4 0 0.9 0 1.3-0.1 0.5-0.1 0.9-0.4 1-0.8 0.2-0.5-0.1-1-0.3-1.5q-0.6-1.2-1.2-2.4c-0.1-0.3-0.2-0.5-0.4-0.7-0.5-0.3-1.2 0.1-1.5 0.6-0.4 0.5-0.6 1.1-1.1 1.5-0.4 0.2-1 0.2-1.1-0.3-0.1-0.2 0.1-0.5 0.4-0.6 0.2-0.2 0.5-0.2 0.8-0.3 0.7-0.4 1.1-1.3 1.2-2.2 0.1-0.8-0.1-1.7 0.1-2.5 0.2-1.2 1-2.2 1.7-3.2 0.3-0.4 0.6-0.8 1-1 0.5-0.2 1.1-0.2 1.4 0.2 0.4 0.4 0.1 1.1-0.3 1.6-0.4 0.4-0.9 0.7-1.3 1.2-0.5 0.6-0.5 1.5-0.4 2.3 0.2 0.8 0.6 1.5 1 2.2 0.2 0.5 0.5 0.9 1 1.1 0.6 0.2 1.3-0.3 1.8-0.7 0.7-0.6 1.5-1.2 2.5-1.2 0.5 0.1 0.9 0.3 1.1 0.7 0.5 0.8-0.1 1.6-0.9 1.9-0.6 0.3-1.2 0.3-1.8 0.4-0.9 0.1-1.9 0.6-2.1 1.5-0.1 1 0.9 1.9 0.8 2.9-0.2 1.4-2.2 2.3-0.4 3.5 1.2 0.8 2.6 1.3 4 1.4z" />
								<g>
									<path className="s7" d="m187.7 117.2c0.3 0 0.5-0.5 0.2-0.7-0.8-0.6-1.7-1.1-2.6-1.9-1-0.8-1.7-1.5-2.4-2.2-0.3-0.3-0.7 0-0.7 0.4 0.6 2.3 3.1 4.3 5.5 4.4z" />
								</g>
								<g>
									<path className="s7" d="m183.7 96.2c0.3-0.2 0-0.7-0.4-0.6-1.3 0.4-2.4 1.3-3.1 2.5-0.7 1.2-1 2.6-0.8 3.9 0.1 0.4 0.7 0.4 0.8 0 0.7-2.4 1.6-3.9 3.5-5.8z" />
								</g>
								<g>
									<path className="s7" d="m196.8 99c0.2 0.2 0.7 0.1 0.7-0.2 0.2-0.8-0.1-1.8-0.6-2.5-0.6-0.7-1.4-1.3-2.2-1.4-0.4 0-0.6 0.4-0.4 0.6 0.3 0.3 0.6 0.8 1.3 1.7 0.7 1 1 1.5 1.2 1.8z" />
								</g>
								<g>
									<path className="s7" d="m197.7 106.5c-0.4 1.4-0.6 3.3-0.5 4.8 0 0.3 0.5 0.5 0.7 0.2 1-1.4 1.3-3.5 0.6-5-0.2-0.4-0.6-0.3-0.8 0z" />
								</g>
							</g>
							{/* tooth 27 */}
							<g
								className={`svg_clickable ${config.teethSelected.includes(27) ? 'selected' : ''}`}
								onClick={() => onClick(27)}
							>
								<path className="s4" d="m173.8 134.8c0 1.9 0.1 7.5 3.9 9.7 2 1.1 4.8 1.7 7.9 2 2.9 0.2 6.1 0 9.1-0.3 6-0.7 6.4-6.4 6.4-8.7 0.1-2.3 0.4-6.5 0.4-6.7 1-8.3-4-9.4-6.7-10.2-0.8-0.3-2-0.3-3.6-0.1-4.1 0.3-10.2 1.8-13.5 3.5-4.5 2.2-3.9 8.8-3.9 10.8z" />
								<path className="s5" d="m177.8 134.8c0 2.1 0 7.9 3.8 10.2 1.1 0.6 2.4 1.1 4 1.5 2.9 0.2 6.1 0 9.1-0.3 6-0.7 6.4-6.4 6.4-8.7 0.1-2.3 0.4-6.5 0.4-6.7 1-8.3-4-9.4-6.7-10.2-0.8-0.3-2-0.3-3.6-0.1-3.4 0.6-7.2 1.8-9.6 3-4.5 2.3-3.8 9.2-3.8 11.3z" />
								<path className="s6" d="m195.4 144.4c3.2-0.4 4.6-2.5 4.7-7 0.1-2.3 0.4-6.6 0.5-6.8 0.7-6.3-2.4-7.3-5-8.1-0.8-0.2-2.1-0.4-3.2-0.2-3.4 0.7-7 1.9-9 2.9-3.1 1.6-3 6.5-2.9 8.8q0 0.5 0 0.8c0 4.5 1 7.4 3 8.6q1.2 0.8 3.2 1.2c2.8 0.2 5.9 0.1 8.7-0.2z" />
								<path className="s5" d="m191.3 140.7q-0.9-0.2-1.9-0.4-0.7-0.2-1.5-0.4-1-0.2-1.9-0.4c-0.2 0-0.5-0.1-0.6-0.3-0.2-0.2 0.1-0.6 0.4-0.7 0.3-0.1 0.6 0 1 0.1q0.9 0.3 1.9 0.6c0.2 0.1 0.4 0.1 0.6 0 0.3-0.1 0.3-0.4 0.4-0.6 0.2-0.8 0.4-1.6 0.4-2.5-0.1-1.5-1.3-2.9-2.7-3.3-0.3 0-0.6-0.1-0.8-0.2-0.3-0.2-0.5-0.4-0.4-0.7 0-0.3 0.4-0.5 0.7-0.5 0.3 0 0.5 0.1 0.8 0.3q0.8 0.4 1.6 0.8c0.3 0.1 0.6 0.2 0.9 0.1 0.2-0.2 0.3-0.5 0.3-0.8q0.1-1 0.1-2c0.1-0.6 0-1.3-0.4-1.7-0.6-0.5-1.4-0.2-2 0.2-0.6 0.4-1.2 0.9-1.9 0.9-0.3-0.1-0.5-0.1-0.7-0.3-0.1-0.2-0.2-0.5 0-0.7 0.1-0.2 0.4-0.2 0.6-0.3 1.9-0.3 3.7-1.3 4.9-2.8 0.3-0.3 0.5-0.6 0.9-0.9 0.3-0.3 0.7-0.4 1.1-0.3 0.4 0.1 0.8 0.5 0.7 0.9-0.2 0.5-0.8 0.6-1.2 1-0.7 0.5-0.8 1.4-0.8 2.2q-0.1 1.5-0.1 2.9c0 0.4 0 0.8 0.2 1.1 0.2 0.4 0.6 0.5 1 0.6 0.4 0 0.7-0.1 1.1-0.3 0.3-0.2 0.5-0.4 0.9-0.5 0.3-0.1 0.7-0.1 0.9 0.2 0.2 0.3 0.1 0.7-0.2 1-0.2 0.2-0.6 0.3-1 0.3-0.3 0-0.7 0.1-1 0.2-0.6 0.2-1.1 0.7-1.3 1.3-0.3 0.6-0.4 1.2-0.5 1.8-0.1 1.1-0.2 2.4 0.5 3.2 0.4 0.4 1 0.7 1.4 1 0.2 0.2 0.8 0.7 0.7 1-0.1 0.4-0.5 0-0.7-0.1-0.8-0.5-1.6-0.7-2.4-1z" />
								<g>
									<path className="s7" d="m187.6 142.7c0.3 0 0.4-0.3 0.1-0.4-0.9-0.5-2.1-0.9-2.9-1.6-0.7-0.8-0.8-1.6-1.1-2.5-0.1-0.2-0.4-0.1-0.5 0.1-0.2 1.2-0.1 2.4 0.8 3.3 0.8 0.8 2.4 1.2 3.6 1.1z" />
								</g>
								<g>
									<path className="s7" d="m185.1 127c0.1-0.2-0.1-0.5-0.4-0.3-1 0.6-1.7 1.8-2.1 3-0.3 1.1-0.2 2.4 0 3.6 0.1 0.3 0.5 0.3 0.6 0 0.6-1.8 0.7-3.8 1.9-6.3z" />
								</g>
								<g>
									<path className="s7" d="m195.5 125.1c-0.5 0.3 1.5 0.6 1.8 1.3 0.3 0.7 0.4 1.5 0.5 2.3 0.3 3.4 0.1 6.6-0.5 9.8q-0.3 1.2-0.6 2.4c-0.1 0.8-2.2 1.3-1.6 1.5 0.6 0.1 1.4-0.1 1.9-0.6 0.1-0.2 0.3-0.5 0.3-0.6 2.1-4 2.5-8.9 1.6-12.7-0.2-0.8-0.5-1.7-1-2.5-0.7-0.9-1.8-1.2-2.4-0.9z" />
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	)
}

export default Mouse2D
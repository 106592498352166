import emailjs from 'emailjs-com';
import { ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdCloseCircle } from 'react-icons/io';
import Modal from 'react-modal';
import { Config } from 'src/sections/Mouth/types';

interface MouseModalProps {
	config: Config;
	closeModal: () => void;
}

const MouseModal = ({
	config,
	closeModal
}: MouseModalProps) => {
	const { t } = useTranslation(['common', 'contact', 'mouth']);
	const form = useRef<any>();

	const onSubmit = (e: ChangeEvent<HTMLFormElement>) => {
		e.preventDefault();

		const { email, phone, instagram, message } = e.target

		const emailContent = {
			toothSelected: config.teethSelected.toString(),
			texture: config.texture,
			style: config.style,
			email: email.value,
			phone: phone.value,
			instagram: instagram.value,
			message: message.value
		}

		emailjs.send('service_dx5ni5b', 'template_26dih69', emailContent, 'IL9h0g2iG0xgz2R_e');

		e.target.reset();
		closeModal();
	}

	return (
		<Modal
			className="modal"
			overlayClassName="modal__overlay"
			isOpen={true}
			onRequestClose={closeModal}
		>
			<h2 className="modal__title">{t('mouth:modal.title')}</h2>
			<IoMdCloseCircle className="modal__icon-close" onClick={closeModal} />
			<form className='contact__form' ref={form} onSubmit={onSubmit}>
				<fieldset>
					<input type="email" name='email' placeholder={t('contact:form.mail')} required />
					<input type="text" name='phone' placeholder={t('contact:form.phone')} />
					<input type="text" name='instagram' placeholder={t('contact:form.instagram')} />
				</fieldset>
				<fieldset>
					<textarea name="message" rows={7} placeholder={t('contact:form.explanation')}></textarea>
				</fieldset>
				<button type='submit'>{t('common:submit')}</button>
			</form>
		</Modal>
	);
}

export default MouseModal;
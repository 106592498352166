import InstagramFeed from '../../components/InstagramFeed'
import '../../components/InstagramFeed/styles.module.css'

import './styles.css'

// https://www.npmjs.com/package/react-ig-feed
// https://mohammedraji.github.io/posts/get-instagram-graph-API/

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <div className='container portfolio__container'>
        <InstagramFeed token="IGQWRNeTduSVhWX0hSS19qNWpxQ2IzOEFxUEt4eGdtRXRseUdMZA1NwcmZASUnFQQWlkbDh5WTFUVzcwTU5ZAbmVOcmg3WTFtbTFFa3pfSGRDTWRFMkFFb3I5NkhWcFNSTmdzbWMxV21vVEhIN0ZA2SmFhWFlCQnN0cjAZD" />
      </div>
    </section>
  )
}

export default Portfolio

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    AiOutlineCloseCircle,
    AiOutlineMenu
} from 'react-icons/ai'
import LOGO from '../../assets/logo.png'
import { isMobile } from '../../helpers/is-mobile'
import LanguageSwitch from '../LanguageSwitch'
import './styles.css'

const Header = () => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const handleToggleMenuMobile = () => {
        setIsOpen(!isOpen)
    }

    return (
        <header id={`header`} className={`${isMobile() ? 'mobile' : 'desktop'}`}>
            {isMobile() ?
                <div>
                    <div id="logo">
                        <img src={LOGO} alt="logo" />
                    </div>

                    <LanguageSwitch />
                    <AiOutlineMenu onClick={handleToggleMenuMobile} />

                    {isOpen && <div className='menu_mobile_panel'>
                        <AiOutlineCloseCircle onClick={handleToggleMenuMobile} />
                        <div id="links">
                            <ul>
                                <li>
                                    <a href="#portfolio" onClick={handleToggleMenuMobile}>{t('header.gallery')}</a>
                                </li>
                                <li>
                                    <a href="#aboutus" onClick={handleToggleMenuMobile}>{t('header.about_us')}</a>
                                </li>
                                <li>
                                    <a href="#contact" onClick={handleToggleMenuMobile}>{t('header.contact')}</a>
                                </li>
                            </ul>
                        </div>
                    </div>}
                </div>
                :
                <div>
                    <div id="logo">
                        <img src={LOGO} alt="logo" />
                    </div>
                    <div id="links">
                        <ul>
                            <li>
                                <a href="#portfolio">{t('header.gallery')}</a>
                            </li>
                            <li>
                                <a href="#aboutus">{t('header.about_us')}</a>
                            </li>
                            <li>
                                <a href="#contact">{t('header.contact')}</a>
                            </li>
                        </ul>
                    </div>
                    <LanguageSwitch />
                </div>
            }
        </header>
    )
}

export default Header


import { useState } from "react";
import { useTranslation } from 'react-i18next';
import Mouse2D from 'src/sections/Mouth/2D';
import Mouse3D from 'src/sections/Mouth/3D';
import MouthConfigurator from 'src/sections/Mouth/Configurator';
import MouseModal from 'src/sections/Mouth/Modal';
import { Config } from 'src/sections/Mouth/types';
import './styles.css';

// https://designcode.io/react-hooks-handbook-threejs-1
// https://designcode.io/react-hooks-handbook-threejs-2
// https://github.com/CesiumGS/gltf-pipeline
// https://github.com/pmndrs/gltfjsx
// https://gltf.pmnd.rs/

// gltf-pipeline -i mouth.glb -o mouth.gltf -d

const MouthContainer = () => {
    const { t } = useTranslation('mouth');

    const [config, setConfig] = useState<Config>({
        teethSelected: [],
        texture: undefined,
        style: undefined,
        withDiamond: false
    });

    const [isClickable, setIsClickable] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <section id='mouth' className={isClickable ? 'clickable' : ''}>
            <div className='container '>
                <h3 className='mouth__configurator__title'>{t('mouth:title')}</h3>
                <p className='mouth__configurator__explanations'>{t('mouth:explanations')}</p>
                <div className='mouth__container'>
                    <MouthConfigurator
                        config={config}
                        setConfig={setConfig}
                        openModal={() => { setIsModalOpen(true); }}
                    />
                    <Mouse2D
                        config={config}
                        setConfig={setConfig}
                    />
                    <Mouse3D
                        config={config}
                        setConfig={setConfig}
                        setIsClickable={setIsClickable}
                    />
                </div>

                {isModalOpen && (
                    <MouseModal
                        config={config}
                        closeModal={() => { setIsModalOpen(false); }}
                    />
                )}
            </div>
        </section>
    );
};

export default MouthContainer;
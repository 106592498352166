import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import i18nextBackend from 'i18next-http-backend';

i18n
    .use(i18nextBackend)
    .use(initReactI18next)
    .init({
        lng: 'es',
        fallbackLng: 'es',
        supportedLngs: ['es', 'en'],
        preload: ['es'],
        defaultNS: 'common',
        returnNull: false,
        interpolation: {
            escapeValue: false
        },
        react: {
            useSuspense: true
        },
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json'
        },
        debug: false
    });


export default i18n;
/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useGLTF } from "@react-three/drei";
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { generateTeeth } from 'src/sections/Mouth/3D/constants';
import { ContextConfigParams, GLTFResult, Styles, Textures } from 'src/sections/Mouth/types';

interface ModelProps {
    setIsClickable: Dispatch<SetStateAction<boolean>>;
}

export const Model = ({
    config,
    setConfig,
    setIsClickable,
    ...props
}: ContextConfigParams & ModelProps & JSX.IntrinsicElements["group"]) => {
    const [teethSelected, setTeethSelected] = useState<number[]>([]);

    useEffect(() => {
        setTeethSelected(config.teethSelected)
    }, [config.teethSelected])

    useEffect(() => {
        setConfig({
            ...config,
            teethSelected
        })
    }, [teethSelected])

    const { nodes, materials } = useGLTF("/mouth.gltf") as unknown as GLTFResult;
    const teeth = generateTeeth(nodes);

    return (
        <group {...props}>
            {teeth.map(tooth => {
                const isSelected = teethSelected.includes(tooth.id);
                const materialSelected = config?.texture
                    ? materials[config?.texture]
                    : materials.selected;
                const material = isSelected ? materialSelected : materials.white;
                const baseMaterial = config?.style === Styles.window ? materials.white : material;

                const isWindowSelected = config?.style === Styles.window;
                const isBeveledSelected = config?.style === Styles.beveled;
                const isSmoothSelected = config?.style === Styles.smooth;
                const isDiamondSelected = !!config?.withDiamond;

                return (
                    <group
                        key={`tooth_${tooth.id}`}
                    // onPointerEnter={() => { setIsClickable(true); }}
                    // onPointerLeave={() => { setIsClickable(false); }}
                    // onClick={() => {
                    //     const newTeethSelected = [...teethSelected];

                    //     if (newTeethSelected.includes(tooth.id)) {
                    //         const indexToRemove = newTeethSelected.findIndex(i => i === tooth.id)
                    //         newTeethSelected.splice(indexToRemove, 1);
                    //     } else {
                    //         newTeethSelected.push(tooth.id);
                    //     }

                    //     setTeethSelected(newTeethSelected);
                    // }}
                    >
                        <mesh
                            castShadow
                            receiveShadow
                            geometry={tooth.base?.element}
                            material={baseMaterial}
                            position={tooth.base?.position}
                            scale={tooth.base?.scale}
                        />

                        {(isSmoothSelected && isDiamondSelected && isSelected) && (
                            <mesh
                                castShadow
                                receiveShadow
                                geometry={tooth.diamond?.element}
                                material={materials[Textures.diamond]}
                                position={tooth.diamond?.position}
                                scale={tooth.diamond?.scale}
                                rotation={tooth.diamond?.rotation}
                            />
                        )}

                        {(isWindowSelected && isSelected) && (
                            <mesh
                                castShadow
                                receiveShadow
                                geometry={tooth.window?.element}
                                material={material}
                                position={tooth.window?.position}
                                scale={tooth.window?.scale}
                                rotation={tooth.window?.rotation}
                            />
                        )}
                        {(isWindowSelected && isDiamondSelected && isSelected) && (
                            <mesh
                                castShadow
                                receiveShadow
                                geometry={tooth.windowDiamond?.element}
                                material={materials[Textures.diamond]}
                                position={tooth.windowDiamond?.position}
                                scale={tooth.windowDiamond?.scale}
                                rotation={tooth.windowDiamond?.rotation}
                            />
                        )}
                        {(isBeveledSelected && isSelected) && (
                            <mesh
                                castShadow
                                receiveShadow
                                geometry={tooth.beveled?.element}
                                material={material}
                                position={tooth.beveled?.position}
                                scale={tooth.beveled?.scale}
                                rotation={tooth.beveled?.rotation}
                            />
                        )}
                    </group>
                );
            })}
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.jaw_upper.geometry}
                material={materials.gum}
                position={[1.427, 7.586, 2.547]}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.jaw_lower.geometry}
                material={materials.gum}
                position={[2.422, -5.576, 4.149]}
            />
        </group>
    );
}

useGLTF.preload("/mouth.gltf");

import { OrbitControls, Stage } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { Dispatch, SetStateAction, Suspense } from 'react';
import { Model } from 'src/sections/Mouth/3D/Model';
import { ContextConfigParams } from 'src/sections/Mouth/types';

interface Mouse3DProps {
	setIsClickable: Dispatch<SetStateAction<boolean>>;
}

const Mouse3D = ({
	config,
	setConfig,
	setIsClickable
}: ContextConfigParams & Mouse3DProps) => {
	// autorotate
	// reset
	// zoom
	return (
		<>
			<Canvas
				gl={{ preserveDrawingBuffer: true }}
				shadows
				dpr={[1, 1.5]}
				camera={{ position: [0, -30, 150], fov: 30 }}
			>
				<OrbitControls
					enableZoom={false}
					minPolarAngle={1.5}
					maxPolarAngle={1.5}
				/>
				<ambientLight intensity={0.25} />
				<Suspense fallback={null}>
					<Stage
						intensity={1}
						shadows
						adjustCamera
						environment="city"
					>
						<Model
							config={config}
							setConfig={setConfig}
							setIsClickable={setIsClickable}
						/>
					</Stage>
				</Suspense>
			</Canvas>
		</>
	);
}

export default Mouse3D
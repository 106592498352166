import { Player } from 'video-react';
import './styles.css';

import { useTranslation } from 'react-i18next';
import VIDEO from '../../assets/video.mp4';

const Video = () => {
    const { t } = useTranslation('video');

    return (
        <section id='video'>
            <div className='container video__container'>
                <div className='video__informations'>
                    <h1 className='video__title'>{t('video:title')}</h1>

                    <div className='video_informations_buttons'>
                        <a href="#mouth" className='mouth__configurator__button'>{t('video:button_1')}</a>
                        <a href="#contact" className='contact__button'>{t('video:button_2')}</a>
                    </div>
                </div>
                <Player
                    autoPlay
                    muted
                    // @ts-ignore
                    loop
                >
                    <source src={VIDEO} />
                </Player>
            </div>
        </section>
    )
}

export default Video
